export const TXN_FORM_UPDATE_TXN_TYPE = 'TXN_FORM_UPDATE_TXN_TYPE';

export const SHOW_ADD_TXN_FORM = 'SHOW_ADD_TXN_FORM';

export const SHOW_EDIT_TXN_FORM = 'SHOW_EDIT_TXN_FORM';

export const TXN_FORM_ADD_TXN_REQUEST = 'TXN_FORM_ADD_TXN_REQUEST';
export const TXN_FORM_ADD_TXN_SUCCESS = 'TXN_FORM_ADD_TXN_SUCCESS';
export const TXN_FORM_ADD_TXN_FAILURE = 'TXN_FORM_ADD_TXN_FAILURE';

export const TXN_FORM_LOAD_ALL_TXN_REQUEST = 'TXN_FORM_LOAD_ALL_TXN_REQUEST';
export const TXN_FORM_LOAD_ALL_TXN_SUCCESS = 'TXN_FORM_LOAD_ALL_TXN_SUCCESS';
export const TXN_FORM_LOAD_ALL_TXN_FAILURE = 'TXN_FORM_LOAD_ALL_TXN_FAILURE';

export const TXN_FORM_DELETE_TXN_REQUEST = 'TXN_FORM_DELETE_TXN_REQUEST';
export const TXN_FORM_DELETE_TXN_SUCCESS = 'TXN_FORM_DELETE_TXN_SUCCESS';
export const TXN_FORM_DELETE_TXN_FAILURE = 'TXN_FORM_DELETE_TXN_FAILURE';

export const TXN_FORM_LOAD_TXN_REQUEST = 'TXN_FORM_LOAD_TXN_REQUEST';
export const TXN_FORM_LOAD_TXN_SUCCESS = 'TXN_FORM_LOAD_TXN_SUCCESS';
export const TXN_FORM_LOAD_TXN_FAILURE = 'TXN_FORM_LOAD_TXN_FAILURE';

export const TXN_FORM_EDIT_TXN_REQUEST = 'TXN_FORM_EDIT_TXN_REQUEST';
export const TXN_FORM_EDIT_TXN_SUCCESS = 'TXN_FORM_EDIT_TXN_SUCCESS';
export const TXN_FORM_EDIT_TXN_FAILURE = 'TXN_FORM_EDIT_TXN_FAILURE';

export const RESET_TXN_STATE_REQUEST = 'RESET_TXN_STATE_REQUEST';

export const REFRESH_POSITION_REQUEST = 'REFRESH_POSITION_REQUEST';
export const REFRESH_POSITION_SUCCESS = 'REFRESH_POSITION_SUCCESS';
export const REFRESH_POSITION_FAILURE = 'REFRESH_POSITION_FAILURE';

export const SEARCH_SECURITY_REQUEST = 'SEARCH_SECURITY_REQUEST';
export const SEARCH_SECURITY_SUCCESS = 'SEARCH_SECURITY_SUCCESS';
export const SEARCH_SECURITY_FAILURE = 'SEARCH_SECURITY_FAILURE';

export const SELECT_EXCHANGE_REQUEST = 'SELECT_EXCHANGE_REQUEST';
export const SELECT_EXCHANGE_SUCCESS = 'SELECT_EXCHANGE_SUCCESS';
export const SELECT_EXCHANGE_FAILURE = 'SELECT_EXCHANGE_FAILURE';

export const SELECT_SECURITY_REQUEST = 'SELECT_SECURITY_REQUEST';
export const SELECT_SECURITY_SUCCESS = 'SELECT_SECURITY_SUCCESS';
export const SELECT_SECURITY_FAILURE = 'SELECT_SECURITY_FAILURE';
