//import liraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Input, Tooltip, Icon, Button, Form } from 'antd';
import { auth } from '../../services/User/actions';
import { GoogleLogin } from 'react-google-login';
import { GoogleButton } from 'react-google-button';

// create a component
class LoginForm extends Component {

    componentWillMount() {
    
    }

    render() {
        const handleSubmit = (evt) => {
            evt.preventDefault();

            this.props.form.validateFields(
                (err) => {
                    if (!err) {
                        this.props.auth(
                            this.props.form.getFieldValue('username'),
                            this.props.form.getFieldValue('password'),
                            'User/Pass',
                            null
                        );
                    }
                }
            )
        }

        const { getFieldDecorator } = this.props.form;

        const onGoogleLoginSuccess = (response) => {
            //console.log('onGoogleLoginSuccess', response);
            this.props.auth(
                response.profileObj.email,
                null,
                'GoogleLogin',
                response.tokenObj.id_token
            )
        }

        const onGoogleLoginFailure = () => {
            //console.error('onGoogleLoginFailure');
        }

        
        return (

            <Form onSubmit={handleSubmit}>
                <Card 
                    style={{ width:300, height:450, boxShadow: '1px 1px 5px #888888'  }} 
                    title={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems:' center' }}>
                            <span style={{fontSize:'20px'}}><Icon style={{color:'#fa8c16'}} type="deployment-unit" /> POLAR</span>
                        </div>
                    } 
                    //hoverable
                    //headStyle={{ backgroundColor: '#fa8c16', color:'rgb(219, 219, 219)'}}
                >

                    <div>
                        Username
                        <Form.Item
                        >

                        {getFieldDecorator("username", {
                            rules: [{ required: true, message: "Username is required" }]
                        })(
                            <Input
                                placeholder="Enter your username"
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                suffix={
                                <Tooltip 
                                    //title="Don't have an account?  Just enter your desired username / password and we'd create it on the spot!"
                                    title="Enter your username to continue"
                                >
                                    <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                                }
                            /> 
                        )}
                        </Form.Item>
                    </div>

                    <div style={{ marginTop: 20}}>
                        Password
                        <Form.Item
                        >
                        {getFieldDecorator("password", {
                            rules: [{ required: true, message: "Password is required" }]
                        })(
                            <Input.Password placeholder="input password" />
                        )}
                        </Form.Item>
                    </div>
                    
                    {/*<div style={{ marginTop: 20, align:'center', display:'flex', justifyContent:'flex-end' }}>
                        <Form.Item>
                            <Button style={{width:250}} htmlType="submit" type='primary' disabled={!this.props.User.loginButtonEnabled}>Log In</Button>
                        </Form.Item>
                    </div>*/}

                    <table>
                        <tbody>
                            <tr><td>
                                <Form.Item style={{padding:0, marginBottom:0}}>
                                    <Button style={{width:250, height:50}} htmlType="submit" type='primary' disabled={!this.props.User.loginButtonEnabled}>Log In</Button>
                                </Form.Item>
                            </td></tr>
                            <tr><td align='center' style={{padding:10}}>or</td></tr>
                            <tr><td align='center'>
                                <GoogleLogin
                                    clientId="975158305366-9t1amfp2g81l9064c59uh7kj2qu9tren.apps.googleusercontent.com"
                                    buttonText="Login with Google"
                                    style={{ }}
                                    onSuccess={onGoogleLoginSuccess}
                                    onFailure={onGoogleLoginFailure}
                                    cookiePolicy={'single_host_origin'}
                                    render={renderProps => (
                                        <GoogleButton 
                                            type='light'
                                            onClick={renderProps.onClick} 
                                            disabled={renderProps.disabled}
                                            style={{ width:'100%' }}
                                        />
                                    )}
                                />
                            </td></tr>
                        </tbody>
                    </table>
                    
                </Card>
                
            </Form>
        );
    }
}


const LoginFormCreated = Form.create()(LoginForm);


const mapStateToProps = state => ({
    User: state.User
});

const mapDispatchToProps = {
    auth
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormCreated);


