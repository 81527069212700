//import libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from '../forms/Login/LoginForm';


// create a component
class Login extends Component {

    componentWillMount(){
        
    }


    render(){
        //window.localStorage.setItem('TEST', JSON.stringify({'abc':123,'def':456}));
        //console.log(JSON.parse(window.localStorage.getItem('TEST')));
        
        return (
            <div style={{ width:'100%', height:'100vh',  display: 'flex', justifyContent: 'center', alignItems:'center',  }}>
                <LoginForm />

            </div>

        );
    }
}


const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

