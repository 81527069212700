import {
    LOAD_SECURITY_LIST_REQUEST,
    ADD_SECURITY_TICKER_MAPPING_REQUEST,
    EDIT_SECURITY_TICKER_MAPPING_REQUEST,
    DELETE_SECURITY_TICKER_MAPPING_REQUEST,
    LOAD_SECURITY_TICKER_MAPPING_REQUEST,
    LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST,
    SHOW_ADD_SECURITY_TICKER_MAPPING_FORM,
    SHOW_EDIT_SECURITY_TICKER_MAPPING_FORM,
    RESET_SECURITY_STATE_REQUEST,
    LOAD_EXCHANGES_REQUEST,
} from './types';

export const loadExchanges = () => ({
    type: LOAD_EXCHANGES_REQUEST
})

export const loadSecurityList = (exchange) => ({
    type: LOAD_SECURITY_LIST_REQUEST,
    payload: {
        exchange: exchange
    }
})

export const addSecurityTickerMapping = ({name, ticker}) => ({
    type: ADD_SECURITY_TICKER_MAPPING_REQUEST,
    payload: {
        name: name,
        ticker: ticker
    }
})

export const showAddSecurityTickerMapping = (show) => ({
    type: SHOW_ADD_SECURITY_TICKER_MAPPING_FORM,
    payload: show
})

export const showEditSecurityTickerMapping = (show) => ({
    type: SHOW_EDIT_SECURITY_TICKER_MAPPING_FORM,
    payload: show
})

export const editSecurityTickerMapping = ({key, name, ticker}) => ({
    type: EDIT_SECURITY_TICKER_MAPPING_REQUEST,
    payload: {
        name: name,
        ticker: ticker,
        key: key
    }
})

export const deleteSecurityTickerMapping = (key) => ({
    type: DELETE_SECURITY_TICKER_MAPPING_REQUEST,
    payload: key
})

export const loadSecurityTickerMapping = (key) => ({
    type: LOAD_SECURITY_TICKER_MAPPING_REQUEST,
    payload: key
})

export const loadAllSecurityTickerMapping = () => ({
    type: LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST,
})

export const resetSecuritiesState = () => ({
    type: RESET_SECURITY_STATE_REQUEST
});