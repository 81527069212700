import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    CHECK_TOKEN_REQUEST,
    CHECK_TOKEN_SUCCESS,
    CHECK_TOKEN_FAILURE,
} from './types';
import axios from 'axios';



function* auth(action) {
    try {
        const token = yield call(
            axios.post,
            `${config.LAMBDA_URL}/auth`,
            {
                ...action.payload
            }
        )
        if ( token.data.status === 'Success' ) {
            yield put({
                type: AUTH_SUCCESS,
                payload: {
                    ...action.payload,
                    ...token.data,
                    //token: token.data.token,
                    //status: token.data.status,
                    //message: token.data.message
                }
            });
        } else {
            yield put({ type: AUTH_FAILURE });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: AUTH_FAILURE });
    }
}

function* checkToken(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call(
            axios.post,
            `${config.LAMBDA_URL}/checkToken`,
            {
                ...action.payload
            },
            {headers: {token:token}}
        )
        if ( ret.data !== '' ) {
            yield put({
                type: CHECK_TOKEN_SUCCESS,
                payload: {
                    token: action.payload.token,
                    ...ret.data,
                    //username: ret.data
                }
            });
        } else {
            yield put({ type: CHECK_TOKEN_FAILURE });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: CHECK_TOKEN_FAILURE });
    }
}




export default function* () {
    yield takeEvery(AUTH_REQUEST, auth);
    yield takeEvery(CHECK_TOKEN_REQUEST, checkToken);
};
