import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Breadcrumb, Icon, Input, Row, Col, Avatar, Badge, Button, notification, AutoComplete } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Routes from '../routes';
import config from '../config';
import { GoogleLogout } from 'react-google-login';

//Import Actions
import { resetSecuritiesState } from '../services/Securities/actions';
import { logout, checkToken } from '../services/User/actions';
import { resetTransactionsState, } from '../services/Transactions/actions';
import { getNotifications, resetNotificationsState } from '../services/Notifications/actions';
import { getFactsheet } from '../services/Factsheet/actions';

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;


class HeaderContentFooter extends Component {
  componentWillMount() {
    this.props.getNotifications();
    //this.props.loadFactsheetCompanyList();
  } 

  onSelect = (e) => {
    this.props.getFactsheet(e.target.value);
    this.props.history.push('/factsheet');
  };

  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  logout = () => {
    this.props.logout();
    this.props.resetSecuritiesState();
    this.props.resetTransactionsState();
    //this.props.resetNotificationsState();
  }

  showNotifications = () => {
    for (var i=0;i < this.props.Notifications.notifications.length;i++) {
      notification.open({
        message: 'Notifications',
        description: this.props.Notifications.notifications[i].details,
        icon: <Icon type="info-circle" style={{ color: '#fa8c16' }} />,
      });
    }
    this.props.resetNotificationsState();
  }

  componentDidUpdate() {
    this.showNotifications();
  }

  onGoogleLogoutSuccess = (response) => {
      //console.log('onGoogleLogoutSuccess', response);
  }

  onGoogleLogoutFailure = () => {
      //console.error('onGoogleLogoutFailure');
  }
  
  render() {

    
    

    return (
      <Layout className="layout" >
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          //trigger={null}
          //collapsible
          collapsed={this.state.collapsed}
          //collapsed={false}
          onCollapse={this.onCollapse}
          theme='light'
          style={{background:'white', boxShadow: '1px 1px 5px #888888'}}
        >

          {/*<div style={{ display: 'flex', justifyContent: 'center', alignItems:' center', paddingTop: '10px', paddingBottom: '10px' }}>
            <div style={{ width:'100px', height:'100px', background:'grey', justifyContent: 'center', alignItems:' center', display:'flex' }}>
              <span style={{color:'white', fontSize:'20px'}}><Icon type="deployment-unit" /> POLAR</span>
            </div>
          </div>*/}

          <div style={{ display: 'flex', justifyContent: 'center', alignItems:' center', paddingTop: '15px', paddingBottom: '15px' }}>
            <span style={{fontSize:'20px'}}><Icon style={{color:'#fa8c16'}} type="deployment-unit" /> POLAR</span>
          </div>
          
          <Menu theme='white' defaultSelectedKeys={['portfolio']}  mode="inline">

            <Menu.Item key="portfolio" >
              <Link to="/portfolio"><Icon type="area-chart" /><span>Portfolio</span></Link>
            </Menu.Item>

            {/*
            <Menu.Item key="research" >
              <Link to="/"><Icon type="experiment" /><span>Research</span></Link>
            </Menu.Item>
            */}

            {/*
            <SubMenu
              key="sub-analytics"
              title={<span><Icon type="area-chart" /><span>Analytics</span></span>}
            >
              <Menu.Item key="2"><Link to="/performance"><Icon type="stock" />Performance</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/exposure"><Icon type="pie-chart" />Exposure</Link></Menu.Item>
              <Menu.Item key="4"><Link to="/dividend"><Icon type="bar-chart" />Dividend</Link></Menu.Item>
            </SubMenu>
            
            <Menu.Item key="2"><Link to="/performance"><Icon type="stock" /><span>Performance</span></Link></Menu.Item>
            <Menu.Item key="3"><Link to="/exposure"><Icon type="pie-chart" /><span>Exposure</span></Link></Menu.Item>
            <Menu.Item key="4"><Link to="/dividend"><Icon type="bar-chart" /><span>Dividend</span></Link></Menu.Item>
            
            
            <SubMenu
              key="sub-setting"
              title={<span><Icon type="edit" /><span>Settings</span></span>}
            >
              <Menu.Item key="5" ><Link to="/transactions"><Icon type="dollar" /><span>Transactions</span></Link></Menu.Item>
              {this.props.User.username === 'xan' ? <Menu.Item key="6" ><Link to="/security-ticker-mapping"><Icon type="table" />Security - Ticker</Link></Menu.Item> : '' }
            </SubMenu>
            */}

            <Menu.Item key="5" >
              <Link to="/transactions"><Icon type="edit" /><span>Transactions</span></Link>
            </Menu.Item>
            
            {this.props.User.username === 'xan.huang@gmail.com' ? 
              <Menu.Item key="6" >
                <Link to="/security-ticker-mapping"><Icon type="table" />Security - Ticker</Link>
              </Menu.Item> 
              : '' 
            }

            {this.props.User.username === 'xan.huang@gmail.com' ? 
              <Menu.Item key="7" >
                <Link to="/admin"><Icon type="table" />Admin</Link>
              </Menu.Item> 
              : '' 
            }
            
          </Menu>
        </Sider>
        <Layout >
          <Header style={{background:'white', paddingLeft:'30px'}}>
            <Row gutter={10}>
              <Col span={0}>
              <Icon
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
                style={{ color:'white' }}
              />
              </Col>
              <Col 
                xs={12} sm={12} md={12} lg={12} xl={12}//added 2 to take over the space of notification bell
                //xs={14} sm={14} lg={14}
              >
                {/*<Input
                  prefix={<Icon type="search" style={{fontSize:'15px'}}/>}
                  placeholder="input search text"
                  onPressEnter={value => console.log(value)}
                  style={{width:'600', height:'50px',  }} //border: 0, boxShadow: 'none !important', background: 'transparent', borderRadius:0, borderStyle:'hidden'
                />*/}
                  <Input
                    prefix={<Icon type="search" style={{fontSize:'15px'}}/>}
                    placeholder="Enter ticker to search ..."
                    style={{width:'600', height:'40px',  }} 
                    allowClear
                    onPressEnter={this.onSelect}
                  />
              </Col>
              <Col 
                xs={0} sm={8} md={8} lg={8} xl={9} 
                align='right' 
              > {/**#595959 */}
                {this.props.User.name}
              </Col>
              <Col 
                xs={8} sm={2} md={2} lg={2} xl={1}
                align='right'
              >
              {
                this.props.User.picture === '' || this.props.User.picture === null ? 
                <Avatar
                  shape='circle'
                  size='large'
                  style={{ border: '5px', borderColor: 'white' }}
                >
                    {this.props.User.name[0].toUpperCase()}
                </Avatar>
                :
                <Avatar 
                  icon="user" 
                  shape='circle'
                  size='large'
                  style={{ border: '5px', borderColor: 'white' }}
                  src={this.props.User.picture}
                />
              }
              </Col>
              <Col 
                //xs={0} sm={2} md={2} lg={1}
                span={0}
                align='center'
              >
                <Badge dot={true}>
                  <span style={{fontSize:'20px'}}>                      
                    <Icon type='bell' />
                  </span>
                </Badge>
              </Col>
              <Col span={2} align='center'>
                {/*<Button type='primary' onClick={this.logout}>Logout</Button>*/}
                <GoogleLogout
                    clientId="975158305366-9t1amfp2g81l9064c59uh7kj2qu9tren.apps.googleusercontent.com"
                    buttonText="Logout"
                    onLogoutSuccess={this.onGoogleLogoutSuccess}
                    onFailure={this.onGoogleLogoutFailure}
                    render={renderProps => (
                      <Button type='primary' onClick={() => {renderProps.onClick(); this.logout();}} disabled={renderProps.disabled}>Logout</Button>
                    )}
                />
              </Col>
            </Row>
          </Header>
          <Content style={{ padding: '0 00px' }}>
            <div style={{  padding: 24, minHeight: 280 }}> {/*background:'white',*/}
              
                <Routes />

            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <Icon style={{color:'#fa8c16'}} type="deployment-unit" /> POLAR - Portfolio Analytics & Research © {new Date().getFullYear()} 
          </Footer>
        </Layout>
      </Layout>
    )
  }
}



const mapStateToProps = state => ({
  User: state.User,
  Notifications: state.Notifications,
  Search: state.Factsheet.search
});

const mapDispatchToProps = {
    logout,
    resetTransactionsState,
    resetSecuritiesState,
    getNotifications,
    resetNotificationsState,
    getFactsheet,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderContentFooter));
