import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
    LOAD_SECURITY_LIST_REQUEST,
    LOAD_SECURITY_LIST_SUCCESS,
    LOAD_SECURITY_LIST_FAILURE,
    ADD_SECURITY_TICKER_MAPPING_REQUEST,
    ADD_SECURITY_TICKER_MAPPING_SUCCESS,
    ADD_SECURITY_TICKER_MAPPING_FAILURE,
    EDIT_SECURITY_TICKER_MAPPING_REQUEST,
    EDIT_SECURITY_TICKER_MAPPING_SUCCESS,
    EDIT_SECURITY_TICKER_MAPPING_FAILURE,
    LOAD_SECURITY_TICKER_MAPPING_REQUEST,
    LOAD_SECURITY_TICKER_MAPPING_SUCCESS,
    LOAD_SECURITY_TICKER_MAPPING_FAILURE,
    LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST,
    LOAD_ALL_SECURITY_TICKER_MAPPING_SUCCESS,
    LOAD_ALL_SECURITY_TICKER_MAPPING_FAILURE,
    DELETE_SECURITY_TICKER_MAPPING_REQUEST,
    DELETE_SECURITY_TICKER_MAPPING_SUCCESS,
    DELETE_SECURITY_TICKER_MAPPING_FAILURE,
    LOAD_EXCHANGES_REQUEST,
    LOAD_EXCHANGES_SUCCESS,
    LOAD_EXCHANGES_FAILURE,
} from './types';


function* loadExchanges(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/exchangeList`,
            {headers: {token:token}}
        );
        yield put({
            type: LOAD_EXCHANGES_SUCCESS,
            payload: ret.data
        });
    } catch (err) { 
        console.error(err);
        yield put({
            type: LOAD_EXCHANGES_FAILURE,
        })
    }
}

function* loadSecurityList(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/securityList?exchange=${action.payload.exchange}`,
            {headers: {token:token}}
        );
        //var securities = []
        //ret.data.map( (name) => { securities.push({name: name}) } ) 
        yield put({
            type: LOAD_SECURITY_LIST_SUCCESS,
            payload: ret.data
        });
    } catch (err) { 
        console.error(err);
        yield put({
            type: LOAD_SECURITY_LIST_FAILURE,
        })
    }
}

function* addSecurityTickerMapping(action) {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.post,
            `${config.LAMBDA_URL}/securities`,
            {
                ...action.payload
            },
            {headers: {token:token}}
        )
        yield put({
            type: ADD_SECURITY_TICKER_MAPPING_SUCCESS,
            payload: {
                ...action.payload,
                key: ret.data
            }
        });
    } catch (err) { 
        console.error(err);
        yield put({
            type: ADD_SECURITY_TICKER_MAPPING_FAILURE,
        })
    }
}

function* editSecurityTickerMapping(action) {
    try {
        var token = window.localStorage.getItem("token");
        yield call(
            axios.put,
            `${config.LAMBDA_URL}/securities`,
            {
                ...action.payload,
            },
            {headers: {token: token}}
        )
        yield put({
            type: EDIT_SECURITY_TICKER_MAPPING_SUCCESS,
            payload: action.payload
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: EDIT_SECURITY_TICKER_MAPPING_FAILURE
        })
    }
}

function* deleteSecurityTickerMapping(action) {
    try {
        var token = window.localStorage.getItem("token");
        yield call (
            axios.delete,
            `${config.LAMBDA_URL}/securities?key=${action.payload}`,
            {headers: {token:token}}
        )
        yield put({
            type: DELETE_SECURITY_TICKER_MAPPING_SUCCESS,
            payload: action.payload
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: DELETE_SECURITY_TICKER_MAPPING_FAILURE
        })
    }
}

function* loadSecurityTickerMapping(action) {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
           axios.get,
           `${config.LAMBDA_URL}/securities?key=${action.payload}`,
           {headers: {token:token}}
        )
        yield put({
            type: LOAD_SECURITY_TICKER_MAPPING_SUCCESS,
            payload: {
                ...ret.data,
                key: action.payload
            }
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_SECURITY_TICKER_MAPPING_FAILURE
        })
    }
}

function* loadAllSecurityTickerMapping(action) {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/securities`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_ALL_SECURITY_TICKER_MAPPING_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_ALL_SECURITY_TICKER_MAPPING_FAILURE
        })
    }
}




export default function* () {
    yield takeLatest(LOAD_SECURITY_LIST_REQUEST,loadSecurityList);
    yield takeEvery(ADD_SECURITY_TICKER_MAPPING_REQUEST, addSecurityTickerMapping);
    yield takeEvery(EDIT_SECURITY_TICKER_MAPPING_REQUEST, editSecurityTickerMapping);
    yield takeEvery(DELETE_SECURITY_TICKER_MAPPING_REQUEST, deleteSecurityTickerMapping);
    yield takeEvery(LOAD_SECURITY_TICKER_MAPPING_REQUEST, loadSecurityTickerMapping);
    yield takeEvery(LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST, loadAllSecurityTickerMapping);
    yield takeEvery(LOAD_EXCHANGES_REQUEST, loadExchanges);
};

