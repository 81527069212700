export const LOAD_XIRR_REQUEST = 'LOAD_XIRR_REQUEST';
export const LOAD_XIRR_SUCCESS = 'LOAD_XIRR_SUCCESS';
export const LOAD_XIRR_FAILURE = 'LOAD_XIRR_FAILURE';

export const LOAD_TSPAN_REQUEST = 'LOAD_TSPAN_REQUEST';
export const LOAD_TSPAN_SUCCESS = 'LOAD_TSPAN_SUCCESS';
export const LOAD_TSPAN_FAILURE = 'LOAD_TSPAN_FAILURE';

export const LOAD_PLTSPAN_REQUEST = 'LOAD_PLTSPAN_REQUEST';
export const LOAD_PLTSPAN_SUCCESS = 'LOAD_PLTSPAN_SUCCESS';
export const LOAD_PLTSPAN_FAILURE = 'LOAD_PLTSPAN_FAILURE';

export const LOAD_PERF_METRICS_REQUEST = 'LOAD_PERF_METRICS_REQUEST';
export const LOAD_PERF_METRICS_SUCCESS = 'LOAD_PERF_METRICS_SUCCESS';
export const LOAD_PERF_METRICS_FAILURE = 'LOAD_PERF_METRICS_FAILURE';

export const LOAD_PLBREAKDOWN_REQUEST = 'LOAD_PLBREAKDOWN_REQUEST';
export const LOAD_PLBREAKDOWN_SUCCESS = 'LOAD_PLBREAKDOWN_SUCCESS';
export const LOAD_PLBREAKDOWN_FAILURE = 'LOAD_PLBREAKDOWN_FAILURE';

export const LOAD_PLBREAKDOWN_PCT_REQUEST = 'LOAD_PLBREAKDOWN_PCT_REQUEST';
export const LOAD_PLBREAKDOWN_PCT_SUCCESS = 'LOAD_PLBREAKDOWN_PCT_SUCCESS';
export const LOAD_PLBREAKDOWN_PCT_FAILURE = 'LOAD_PLBREAKDOWN_PCT_FAILURE';

export const LOAD_EXP_METRICS_REQUEST = 'LOAD_EXP_METRICS_REQUEST';
export const LOAD_EXP_METRICS_SUCCESS = 'LOAD_EXP_METRICS_SUCCESS';
export const LOAD_EXP_METRICS_FAILURE = 'LOAD_EXP_METRICS_FAILURE';

export const LOAD_MV_VS_INJECTION_REQUEST = 'LOAD_MV_VS_INJECTION_REQUEST';
export const LOAD_MV_VS_INJECTION_SUCCESS = 'LOAD_MV_VS_INJECTION_SUCCESS';
export const LOAD_MV_VS_INJECTION_FAILURE = 'LOAD_MV_VS_INJECTION_FAILURE';

export const LOAD_EXP_BREAKDOWN_REQUEST = 'LOAD_EXP_BREAKDOWN_REQUEST';
export const LOAD_EXP_BREAKDOWN_SUCCESS = 'LOAD_EXP_BREAKDOWN_SUCCESS';
export const LOAD_EXP_BREAKDOWN_FAILURE = 'LOAD_EXP_BREAKDOWN_FAILURE';

export const LOAD_RECENT_DIVIDENDS_REQUEST = 'LOAD_RECENT_DIVIDENDS_REQUEST';
export const LOAD_RECENT_DIVIDENDS_SUCCESS = 'LOAD_RECENT_DIVIDENDS_SUCCESS';
export const LOAD_RECENT_DIVIDENDS_FAILURE = 'LOAD_RECENT_DIVIDENDS_FAILURE';

export const LOAD_DIVIDENDS_METRICS_REQUEST = 'LOAD_DIVIDENDS_METRICS_REQUEST';
export const LOAD_DIVIDENDS_METRICS_SUCCESS = 'LOAD_DIVIDENDS_METRICS_SUCCESS';
export const LOAD_DIVIDENDS_METRICS_FAILURE = 'LOAD_DIVIDENDS_METRICS_FAILURE';

export const LOAD_DIVIDENDS_LTM_REQUEST = 'LOAD_DIVIDENDS_LTM_REQUEST';
export const LOAD_DIVIDENDS_LTM_SUCCESS = 'LOAD_DIVIDENDS_LTM_SUCCESS';
export const LOAD_DIVIDENDS_LTM_FAILURE = 'LOAD_DIVIDENDS_LTM_FAILURE';

export const LOAD_DIVIDENDS_YEARLY_REQUEST = 'LOAD_DIVIDENDS_YEARLY_REQUEST';
export const LOAD_DIVIDENDS_YEARLY_SUCCESS = 'LOAD_DIVIDENDS_YEARLY_SUCCESS';
export const LOAD_DIVIDENDS_YEARLY_FAILURE = 'LOAD_DIVIDENDS_YEARLY_FAILURE';

export const LOAD_EXP_GROUPED_BY_COUNTRY_REQUEST = 'LOAD_EXP_GROUPED_BY_COUNTRY_REQUEST';
export const LOAD_EXP_GROUPED_BY_COUNTRY_SUCCESS = 'LOAD_EXP_GROUPED_BY_COUNTRY_SUCCESS';
export const LOAD_EXP_GROUPED_BY_COUNTRY_FAILURE = 'LOAD_EXP_GROUPED_BY_COUNTRY_FAILURE';

export const LOAD_EXP_GROUPED_BY_SECTOR_REQUEST = 'LOAD_EXP_GROUPED_BY_SECTOR_REQUEST';
export const LOAD_EXP_GROUPED_BY_SECTOR_SUCCESS = 'LOAD_EXP_GROUPED_BY_SECTOR_SUCCESS';
export const LOAD_EXP_GROUPED_BY_SECTOR_FAILURE = 'LOAD_EXP_GROUPED_BY_SECTOR_FAILURE';

export const LOAD_EXP_GROUPED_BY_INDUSTRY_REQUEST = 'LOAD_EXP_GROUPED_BY_INDUSTRY_REQUEST';
export const LOAD_EXP_GROUPED_BY_INDUSTRY_SUCCESS = 'LOAD_EXP_GROUPED_BY_INDUSTRY_SUCCESS';
export const LOAD_EXP_GROUPED_BY_INDUSTRY_FAILURE = 'LOAD_EXP_GROUPED_BY_INDUSTRY_FAILURE';

export const LOAD_XIRR_BENCHMARK_REQUEST = 'LOAD_XIRR_BENCHMARK_REQUEST';
export const LOAD_XIRR_BENCHMARK_SUCCESS = 'LOAD_XIRR_BENCHMARK_SUCCESS';
export const LOAD_XIRR_BENCHMARK_FAILURE = 'LOAD_XIRR_BENCHMARK_FAILURE';

export const LOAD_PORTFOLIO_SECURITIES_REQUEST = 'LOAD_PORTFOLIO_SECURITIES_REQUEST';
export const LOAD_PORTFOLIO_SECURITIES_SUCCESS = 'LOAD_PORTFOLIO_SECURITIES_SUCCESS';
export const LOAD_PORTFOLIO_SECURITIES_FAILURE = 'LOAD_PORTFOLIO_SECURITIES_FAILURE';

export const FILTER_EXP_SECTOR_BREAKDOWN_REQUEST = 'FILTER_EXP_SECTOR_BREAKDOWN_REQUEST';
// export const FILTER_EXP_SECTOR_BREAKDOWN_SUCCESS = 'FILTER_EXP_SECTOR_BREAKDOWN_SUCCESS';
// export const FILTER_EXP_SECTOR_BREAKDOWN_FAILURE = 'FILTER_EXP_SECTOR_BREAKDOWN_FAILURE';

export const FILTER_EXP_COUNTRY_BREAKDOWN_REQUEST = 'FILTER_EXP_COUNTRY_BREAKDOWN_REQUEST';
// export const FILTER_EXP_COUNTRY_BREAKDOWN_SUCCESS = 'FILTER_EXP_COUNTRY_BREAKDOWN_SUCCESS';
// export const FILTER_EXP_COUNTRY_BREAKDOWN_FAILURE = 'FILTER_EXP_COUNTRY_BREAKDOWN_FAILURE';

export const FILTER_EXP_INDUSTRY_BREAKDOWN_REQUEST = 'FILTER_EXP_INDUSTRY_BREAKDOWN_REQUEST';
// export const FILTER_EXP_INDUSTRY_BREAKDOWN_SUCCESS = 'FILTER_EXP_INDUSTRY_BREAKDOWN_SUCCESS';
// export const FILTER_EXP_INDUSTRY_BREAKDOWN_FAILURE = 'FILTER_EXP_INDUSTRY_BREAKDOWN_FAILURE';

export const FILTER_EXP_CLEAR_REQUEST = 'FILTER_EXP_CLEAR_REQUEST';
// export const FILTER_EXP_CLEAR_SUCCESS = 'FILTER_EXP_CLEAR_SUCCESS';
// export const FILTER_EXP_CLEAR_FAILURE = 'FILTER_EXP_CLEAR_FAILURE';

