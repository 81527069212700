exports.sumBy = (data, keyCol, valueCol) => {
    return data.reduce(function(res, obj) {
        if (!(obj[keyCol] in res))
            res.__array.push(res[obj[keyCol]] = {key:obj[keyCol], value:obj[valueCol]});
        else {
            res[obj[keyCol]]['value'] += obj[valueCol];      }
        return res;
    }, {__array:[]}).__array.sort(function(a,b) { return b['value'] - a['value']; });
}

exports.filterBy = (data, filterCol, filterValue) => {
    return data.filter( (obj) => {
        return obj[filterCol] === filterValue
    })
}
