import React from 'react';
import { notification, Icon } from 'antd';

import {
    AUTH_SUCCESS,
    AUTH_FAILURE,
    AUTH_REQUEST,
    LOGOUT_REQUEST,
    CHECK_TOKEN_SUCCESS,
    CHECK_TOKEN_FAILURE,
    CHECK_TOKEN_REQUEST,
} from './types';

const DEFAULT_STATE = {
    token: null,
    checkTokenLoading: false,
    username: null,
    isValidUser: false,
    message: null,
    status: null,
    loginButtonEnabled: true,
    name: null,
    picture: null,
};

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case CHECK_TOKEN_REQUEST: 
            return {
                ...state,
                checkTokenLoading: true
            };

        case CHECK_TOKEN_FAILURE:
            return {
                ...state,
                token: null,
                username: null,
                isValidUser: false,
                checkTokenLoading: false,
            }

        case CHECK_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                //token: action.payload.token,
                //username: action.payload.username,
                isValidUser: true,
                checkTokenLoading: false
            };
        
        case AUTH_REQUEST:
            return {
                ...state,
                loginButtonEnabled: false
            };

        case AUTH_SUCCESS:
            window.localStorage.setItem("token", action.payload.token);
            notification.open({
                message: 'Login successful!',
                description: `Welcome back ${action.payload.name}!`,
                icon: <Icon type="check-circle" style={{ color: '#fa8c16' }} />,
              });
            return {
                ...state,
                ...action.payload,
                //token: action.payload.token,
                //status: action.payload.status,
                //message: action.payload.message,
                //username: action.payload.username,
                isValidUser: true,
                loginButtonEnabled: true
            };

        case AUTH_FAILURE:
            window.localStorage.setItem("token", null);
            notification.open({
                message: 'Login failure!',
                description: `Check that you have entered the correct username / password.`,
                icon: <Icon type="warning" style={{ color: 'red' }} />,
              });
            return {
                ...state,
                token: null,
                username: null,
                isValidUser: false,
                message: 'Login failure.  Check that you have entered the correct username / password',
                status: 'Failure',
                loginButtonEnabled: true
            };
        case LOGOUT_REQUEST:
            window.localStorage.setItem("token", null);
            notification.open({
                message: 'Logout successful!',
                description: `You have logged out`,
                icon: <Icon type="check-circle" style={{ color: '#fa8c16' }} />,
              });
            return DEFAULT_STATE;

        default: return state;
    }
}
