import {
    LOAD_USER_LIST_REQUEST,
    LOAD_USER_LIST_SUCCESS,
    LOAD_USER_LIST_FAILURE,
    LOAD_PRICES_LAST_REFRESH_DATE_FAILURE,
    LOAD_PRICES_LAST_REFRESH_DATE_SUCCESS,
    LOAD_PRICES_LAST_REFRESH_DATE_REQUEST, 
} from './types';

const DEFAULT_STATE = {
    isLoading: {
        users: false,
        pricesLastRefreshDate: false
    },
    users: [],
    pricesLastRefreshDate: [],
};

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case LOAD_USER_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    users: true
                }
            };

        case LOAD_PRICES_LAST_REFRESH_DATE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    pricesLastRefreshDate: true
                }
            };

        case LOAD_USER_LIST_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    users: false
                }
            };

        case LOAD_PRICES_LAST_REFRESH_DATE_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    pricesLastRefreshDate: false
                }
            };

        case LOAD_USER_LIST_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    users: false
                },
                users: action.payload
            };

        case LOAD_PRICES_LAST_REFRESH_DATE_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    pricesLastRefreshDate: false
                },
                pricesLastRefreshDate: action.payload
            };
        
        default: return state;
    }
}
