export const LOAD_SECURITY_LIST_REQUEST = 'LOAD_SECURITY_LIST_REQUEST';
export const LOAD_SECURITY_LIST_SUCCESS = 'LOAD_SECURITY_LIST_SUCCESS';
export const LOAD_SECURITY_LIST_FAILURE = 'LOAD_SECURITY_LIST_FAILURE';

export const ADD_SECURITY_TICKER_MAPPING_REQUEST = 'ADD_SECURITY_TICKER_MAPPING_REQUEST';
export const ADD_SECURITY_TICKER_MAPPING_SUCCESS = 'ADD_SECURITY_TICKER_MAPPING_SUCCESS';
export const ADD_SECURITY_TICKER_MAPPING_FAILURE = 'ADD_SECURITY_TICKER_MAPPING_FAILURE';

export const SHOW_ADD_SECURITY_TICKER_MAPPING_FORM = 'SHOW_ADD_SECURITY_TICKER_MAPPING_FORM';
export const SHOW_EDIT_SECURITY_TICKER_MAPPING_FORM = 'SHOW_EDIT_SECURITY_TICKER_MAPPING_FORM';

export const LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST = 'LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST';
export const LOAD_ALL_SECURITY_TICKER_MAPPING_SUCCESS = 'LOAD_ALL_SECURITY_TICKER_MAPPING_SUCCESS';
export const LOAD_ALL_SECURITY_TICKER_MAPPING_FAILURE = 'LOAD_ALL_SECURITY_TICKER_MAPPING_FAILURE';

export const LOAD_SECURITY_TICKER_MAPPING_REQUEST = 'LOAD_SECURITY_TICKER_MAPPING_REQUEST';
export const LOAD_SECURITY_TICKER_MAPPING_SUCCESS = 'LOAD_SECURITY_TICKER_MAPPING_SUCCESS';
export const LOAD_SECURITY_TICKER_MAPPING_FAILURE = 'LOAD_SECURITY_TICKER_MAPPING_FAILURE';

export const EDIT_SECURITY_TICKER_MAPPING_REQUEST = 'EDIT_SECURITY_TICKER_MAPPING_REQUEST';
export const EDIT_SECURITY_TICKER_MAPPING_SUCCESS = 'EDIT_SECURITY_TICKER_MAPPING_SUCCESS';
export const EDIT_SECURITY_TICKER_MAPPING_FAILURE = 'EDIT_SECURITY_TICKER_MAPPING_FAILURE';

export const DELETE_SECURITY_TICKER_MAPPING_REQUEST = 'DELETE_SECURITY_TICKER_MAPPING_REQUEST';
export const DELETE_SECURITY_TICKER_MAPPING_SUCCESS = 'DELETE_SECURITY_TICKER_MAPPING_SUCCESS';
export const DELETE_SECURITY_TICKER_MAPPING_FAILURE = 'DELETE_SECURITY_TICKER_MAPPING_FAILURE';

export const RESET_SECURITY_STATE_REQUEST = 'RESET_SECURITY_STATE_REQUEST';

export const LOAD_EXCHANGES_REQUEST = 'LOAD_EXCHANGES_REQUEST';
export const LOAD_EXCHANGES_SUCCESS = 'LOAD_EXCHANGES_SUCCESS';
export const LOAD_EXCHANGES_FAILURE = 'LOAD_EXCHANGES_FAILURE';

