export const GET_FACTSHEET_FAILURE = 'GET_FACTSHEET_FAILURE';
export const GET_FACTSHEET_SUCCESS = 'GET_FACTSHEET_SUCCESS';
export const GET_FACTSHEET_REQUEST = 'GET_FACTSHEET_REQUEST';

export const FACTSHEET_SEARCH_FAILURE = 'FACTSHEET_SEARCH_FAILURE';
export const FACTSHEET_SEARCH_SUCCESS = 'FACTSHEET_SEARCH_SUCCESS';
export const FACTSHEET_SEARCH_REQUEST = 'FACTSHEET_SEARCH_REQUEST';

export const LOAD_FACTSHEET_COMPANY_LIST_FAILURE = 'LOAD_FACTSHEET_COMPANY_LIST_FAILURE';
export const LOAD_FACTSHEET_COMPANY_LIST_SUCCESS = 'LOAD_FACTSHEET_COMPANY_LIST_SUCCESS';
export const LOAD_FACTSHEET_COMPANY_LIST_REQUEST = 'LOAD_FACTSHEET_COMPANY_LIST_REQUEST';

export const LOAD_FACTSHEET_HISTORICAL_PRICE_FAILURE = 'LOAD_FACTSHEET_HISTORICAL_PRICE_FAILURE';
export const LOAD_FACTSHEET_HISTORICAL_PRICE_SUCCESS = 'LOAD_FACTSHEET_HISTORICAL_PRICE_SUCCESS';
export const LOAD_FACTSHEET_HISTORICAL_PRICE_REQUEST = 'LOAD_FACTSHEET_HISTORICAL_PRICE_REQUEST';

export const LOAD_FACTSHEET_XIRR_FAILURE = 'LOAD_FACTSHEET_XIRR_FAILURE';
export const LOAD_FACTSHEET_XIRR_SUCCESS = 'LOAD_FACTSHEET_XIRR_SUCCESS';
export const LOAD_FACTSHEET_XIRR_REQUEST = 'LOAD_FACTSHEET_XIRR_REQUEST';

export const LOAD_FACTSHEET_NOTES_BY_SECURITY_FAILURE = 'LOAD_FACTSHEET_NOTES_BY_SECURITY_FAILURE';
export const LOAD_FACTSHEET_NOTES_BY_SECURITY_SUCCESS = 'LOAD_FACTSHEET_NOTES_BY_SECURITY_SUCCESS';
export const LOAD_FACTSHEET_NOTES_BY_SECURITY_REQUEST = 'LOAD_FACTSHEET_NOTES_BY_SECURITY_REQUEST';

export const SHOW_ADD_NOTES_FORM = 'SHOW_ADD_NOTES_FORM';
export const SHOW_EDIT_NOTES_FORM = 'SHOW_EDIT_NOTES_FORM';

export const ADD_NOTES_REQUEST = 'ADD_NOTES_REQUEST';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAILURE = 'ADD_NOTES_FAILURE';

export const EDIT_NOTES_REQUEST = 'EDIT_NOTES_REQUEST';
export const EDIT_NOTES_SUCCESS = 'EDIT_NOTES_SUCCESS';
export const EDIT_NOTES_FAILURE = 'EDIT_NOTES_FAILURE';

export const DELETE_NOTES_REQUEST = 'DELETE_NOTES_REQUEST';
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';

export const LOAD_NOTES_REQUEST = 'LOAD_NOTES_REQUEST';
export const LOAD_NOTES_SUCCESS = 'LOAD_NOTES_SUCCESS';
export const LOAD_NOTES_FAILURE = 'LOAD_NOTES_FAILURE';