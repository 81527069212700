import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    TXN_FORM_ADD_TXN_REQUEST,
    TXN_FORM_ADD_TXN_SUCCESS,
    TXN_FORM_ADD_TXN_FAILURE,
    TXN_FORM_LOAD_ALL_TXN_REQUEST,
    TXN_FORM_LOAD_ALL_TXN_SUCCESS,
    TXN_FORM_LOAD_ALL_TXN_FAILURE,
    TXN_FORM_DELETE_TXN_REQUEST,
    TXN_FORM_DELETE_TXN_SUCCESS,
    TXN_FORM_DELETE_TXN_FAILURE,
    TXN_FORM_LOAD_TXN_REQUEST,
    TXN_FORM_LOAD_TXN_SUCCESS,
    TXN_FORM_LOAD_TXN_FAILURE,
    TXN_FORM_EDIT_TXN_REQUEST,
    TXN_FORM_EDIT_TXN_SUCCESS,
    TXN_FORM_EDIT_TXN_FAILURE,
    SEARCH_SECURITY_REQUEST,
    SEARCH_SECURITY_SUCCESS,
    SEARCH_SECURITY_FAILURE,
    SELECT_EXCHANGE_REQUEST,
    SELECT_EXCHANGE_SUCCESS,
    SELECT_EXCHANGE_FAILURE,
} from './types';
import axios from 'axios';


function* loadAllTransactions() {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/transactions`,
            {headers: {token:token}}
        )
        yield put({
            type: TXN_FORM_LOAD_ALL_TXN_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: TXN_FORM_LOAD_ALL_TXN_FAILURE });
    }
}

function* addTransaction(action) {
    try {
        var token = window.localStorage.getItem("token");
        const key = yield call(
            axios.post,
            `${config.LAMBDA_URL}/transactions`,
            {
                ...action.payload,
                //user: uid
                //token: token
            },
            {headers: {token:token}}
        )
        yield put({
            type: TXN_FORM_ADD_TXN_SUCCESS,
            payload: {
                ...action.payload,
                key: key.data
            }
        });
    } catch (err) {
        console.error(err);
        yield put({ type: TXN_FORM_ADD_TXN_FAILURE });
    }
}

function* deleteTransaction(action) {
    try {
        var token = window.localStorage.getItem("token");
        yield call(
            axios.delete,
            `${config.LAMBDA_URL}/transactions?key=${action.payload}`,
            {headers: {token:token}}
        )
        yield put({
            type: TXN_FORM_DELETE_TXN_SUCCESS,
            payload: action.payload
        });
    } catch (err) {
        console.error(err);
        yield put({ type: TXN_FORM_DELETE_TXN_FAILURE });
    }
}

function* loadTransaction(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/transactions?key=${action.payload}`,
            {headers: {token:token}}
        )
        yield put({
            type: TXN_FORM_LOAD_TXN_SUCCESS,
            payload: {
                ...ret.data,
                key: action.payload
            }
        });
    } catch (err) {
        console.error(err);
        yield put({ type: TXN_FORM_LOAD_TXN_FAILURE });
    }
}

function* editTransaction(action) {
    try {
        var token = window.localStorage.getItem("token");
        yield call(
            axios.put,
            `${config.LAMBDA_URL}/transactions`,
            {
                //user: uid,
                //token: token,
                ...action.payload
            },
            {headers: {token:token}}
        )
        yield put({
            type: TXN_FORM_EDIT_TXN_SUCCESS,
            payload: action.payload
        });
    } catch (err) {
        console.error(err);
        yield put({ type: TXN_FORM_EDIT_TXN_FAILURE });
    }
}

function* searchSecurity(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/searchSecurity?search=${action.payload.security}&exchange=${action.payload.exchange}`,
            {headers: {token:token}}
        )
        yield put({
            type: SEARCH_SECURITY_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: SEARCH_SECURITY_FAILURE });
    }
}

export default function* () {
    yield takeLatest(TXN_FORM_LOAD_ALL_TXN_REQUEST, loadAllTransactions);
    yield takeEvery(TXN_FORM_ADD_TXN_REQUEST, addTransaction);
    yield takeEvery(TXN_FORM_DELETE_TXN_REQUEST, deleteTransaction);
    yield takeEvery(TXN_FORM_LOAD_TXN_REQUEST,loadTransaction);
    yield takeEvery(TXN_FORM_EDIT_TXN_REQUEST, editTransaction);
    yield takeLatest(SEARCH_SECURITY_REQUEST, searchSecurity);
};
