import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from '../services/store';
import './App.css';
//import 'antd/dist/antd.css';
//import HeaderContentFooter from '../layouts/HeaderContentFooter';
import Home from '../layouts/Home';
import { BrowserRouter } from 'react-router-dom';
//import { ToastContainer } from 'react-toastify';

class App extends Component {
  componentWillMount(){
  }

  render() {
    return (
      <Provider store={store()}>
        <div>
          <BrowserRouter>
            {/*<ToastContainer />*/}
            <Home />
          </BrowserRouter> 
        </div>
      </Provider>
    );
  }
}

export default App;
