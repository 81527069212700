import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    GET_FACTSHEET_SUCCESS,
    GET_FACTSHEET_FAILURE,
    GET_FACTSHEET_REQUEST,
    LOAD_FACTSHEET_COMPANY_LIST_FAILURE,
    LOAD_FACTSHEET_COMPANY_LIST_SUCCESS,
    LOAD_FACTSHEET_COMPANY_LIST_REQUEST,
    LOAD_FACTSHEET_HISTORICAL_PRICE_FAILURE,
    LOAD_FACTSHEET_HISTORICAL_PRICE_SUCCESS,
    LOAD_FACTSHEET_XIRR_SUCCESS,
    LOAD_FACTSHEET_XIRR_FAILURE,
    LOAD_FACTSHEET_NOTES_BY_SECURITY_FAILURE,
    LOAD_FACTSHEET_NOTES_BY_SECURITY_SUCCESS,
    LOAD_FACTSHEET_NOTES_BY_SECURITY_REQUEST,
    ADD_NOTES_REQUEST,
    ADD_NOTES_SUCCESS,
    ADD_NOTES_FAILURE,
    EDIT_NOTES_REQUEST,
    EDIT_NOTES_SUCCESS,
    EDIT_NOTES_FAILURE,
    DELETE_NOTES_REQUEST,
    DELETE_NOTES_SUCCESS,
    DELETE_NOTES_FAILURE,
    FACTSHEET_SEARCH_REQUEST,
    FACTSHEET_SEARCH_SUCCESS,
    FACTSHEET_SEARCH_FAILURE,
} from './types';
import axios from 'axios';


function* getFactsheet(action) {
    //get base data
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/factsheet?ticker=${action.ticker}`,
            {headers: {token:token}}
        )
        yield put({
            type: GET_FACTSHEET_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: GET_FACTSHEET_FAILURE });
    }
}

function* getFactsheetHistoricalPricePosition(action) {
    //get historical prices & positions (if applicable)
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/factsheetHistoricalPrice?ticker=${action.ticker}`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_FACTSHEET_HISTORICAL_PRICE_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_FACTSHEET_HISTORICAL_PRICE_FAILURE });
    }
}

function* getFactsheetNotes(action) {
    //get notes
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/notes?ticker=${action.ticker}`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_FACTSHEET_NOTES_BY_SECURITY_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_FACTSHEET_NOTES_BY_SECURITY_FAILURE });
    }
}

function* getFactsheetXIRR(action) {
    //get security level XIRR
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/xirr?ticker=${action.ticker}`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_FACTSHEET_XIRR_SUCCESS,
            payload: ret.data.xirr
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_FACTSHEET_XIRR_FAILURE });
    }
}




// function* loadFactsheetCompanyList(action) {
//     try {
//         var token = window.localStorage.getItem("token");
//         const ret = yield call (
//             axios.get,
//             `${config.LAMBDA_URL}/factsheetCompanyList`,
//             {headers: {token:token}}
//         )
//         yield put({
//             type: LOAD_FACTSHEET_COMPANY_LIST_SUCCESS,
//             payload: ret.data
//         });
//     } catch (err) {
//         console.error(err);
//         yield put({ type: LOAD_FACTSHEET_COMPANY_LIST_FAILURE });
//     }
// }

function* addNotes(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.post,
            `${config.LAMBDA_URL}/notes`,
            {
                ...action.payload,
                //token: token
            },
            {headers: {token:token}}
        )
        yield put({
            type: ADD_NOTES_SUCCESS,
            payload: {
                ...action.payload,
                id: ret.data
            }
        });
    } catch (err) {
        console.error(err);
        yield put({ type: ADD_NOTES_FAILURE });
    }
}

function* deleteNotes(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.delete,
            `${config.LAMBDA_URL}/notes?id=${action.payload}`,
            {headers: {token:token}}
        )
        yield put({
            type: DELETE_NOTES_SUCCESS,
            payload: action.payload
        });
    } catch (err) {
        console.error(err);
        yield put({ type: DELETE_NOTES_FAILURE });
    }
}

function* editNotes(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.LAMBDA_URL}/notes`,
            {
                ...action.payload,
                //token: token
            },
            {headers: {token:token}}
        )
        yield put({
            type: EDIT_NOTES_SUCCESS,
            payload: {
                ...action.payload
            }
        });
    } catch (err) {
        console.error(err);
        yield put({ type: EDIT_NOTES_FAILURE });
    }
}

// function* factsheetSearch(action) {
//     try {
//         var token = window.localStorage.getItem("token");
//         const ret = yield call (
//             axios.get,
//             `${config.LAMBDA_URL}/searchSecurity?search=${action.security}`,
//             {headers: {token:token}}
//         )
//         yield put({
//             type: FACTSHEET_SEARCH_SUCCESS,
//             payload: ret.data
//         });
//     } catch (err) {
//         console.error(err);
//         yield put({ type: FACTSHEET_SEARCH_FAILURE });
//     }
// }


export default function* () {
    yield takeLatest(GET_FACTSHEET_REQUEST, getFactsheet);
    yield takeLatest(GET_FACTSHEET_REQUEST, getFactsheetNotes);
    yield takeLatest(GET_FACTSHEET_REQUEST, getFactsheetXIRR);
    yield takeLatest(GET_FACTSHEET_REQUEST, getFactsheetHistoricalPricePosition);
    //yield takeLatest(LOAD_FACTSHEET_COMPANY_LIST_REQUEST, loadFactsheetCompanyList);
    yield takeEvery(ADD_NOTES_REQUEST, addNotes);
    yield takeEvery(DELETE_NOTES_REQUEST, deleteNotes);
    yield takeEvery(EDIT_NOTES_REQUEST, editNotes);
    //yield takeLatest(FACTSHEET_SEARCH_REQUEST, factsheetSearch);
    //yield takeLatest(LOAD_FACTSHEET_NOTES_BY_SECURITY_REQUEST, loadFactsheetNotesBySecurity);
    //yield takeEvery(LOAD_FACTSHEET_HISTORICAL_PRICE_REQUEST, loadFactsheetHistoricalPrice);
};
