import {
    TXN_FORM_UPDATE_TXN_TYPE,
    SHOW_ADD_TXN_FORM,
    SHOW_EDIT_TXN_FORM,
    TXN_FORM_ADD_TXN_SUCCESS,
    TXN_FORM_LOAD_ALL_TXN_REQUEST,
    TXN_FORM_LOAD_ALL_TXN_SUCCESS,
    TXN_FORM_LOAD_ALL_TXN_FAILURE,
    TXN_FORM_DELETE_TXN_SUCCESS,
    TXN_FORM_LOAD_TXN_SUCCESS,
    TXN_FORM_EDIT_TXN_SUCCESS,
    RESET_TXN_STATE_REQUEST,
    SEARCH_SECURITY_REQUEST,
    SEARCH_SECURITY_SUCCESS,
    SEARCH_SECURITY_FAILURE,
    SELECT_EXCHANGE_REQUEST,
    SELECT_EXCHANGE_SUCCESS,
    SELECT_EXCHANGE_FAILURE,
    SELECT_SECURITY_REQUEST,
    SELECT_SECURITY_SUCCESS,
    SELECT_SECURITY_FAILURE,
} from './types';

const DEFAULT_STATE = {
    loadingAllTransaction: false,
    showAddTransaction: false,
    showEditTransaction: false,
    transactions: [],
    positions: [],
    currentTransaction: {
        txn_type: "Buy",
        txn_date: "",
        security: "",
        security_distributed: "",
        price: "",
        quantity: "", 
        fees: "", 
        amount: "",
        key: "",
        exchange: "",
    },
    isLoading: {
        //position: false
    },
    currentSecuritySelection: {
        exchange: 'SGX',
        securities: [],
        securitySelected: false,
        security: "",
    }
};
var txns = [];

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case SHOW_ADD_TXN_FORM:
            return {
                ...state,
                currentTransaction: {
                    txn_type: "Buy"
                },
                showAddTransaction: action.payload
            };
        case SHOW_EDIT_TXN_FORM:
            return {
                ...state,
                showEditTransaction: action.payload
            };
        case TXN_FORM_UPDATE_TXN_TYPE:
            return {
                ...state,
                currentTransaction: {
                    ...state.currentTransaction,
                    txn_type: action.payload
                }
            }
        case TXN_FORM_LOAD_ALL_TXN_REQUEST:
            return {
                ...state,
                loadingAllTransaction: true
            };
        case TXN_FORM_LOAD_ALL_TXN_FAILURE:
            return {
                ...state,
                loadingAllTransaction: false
            }
        case TXN_FORM_LOAD_ALL_TXN_SUCCESS:
            return {
                ...state,
                loadingAllTransaction: false,
                transactions: action.payload
            }
        case TXN_FORM_ADD_TXN_SUCCESS:
            txns = state.transactions;
            txns.push(action.payload);
            return {
                ...state,
                transactions: txns
            }
        case TXN_FORM_DELETE_TXN_SUCCESS:
            txns = state.transactions.filter( o => o.key !== action.payload)
            return {
                ...state,
                transactions: txns
            }
        case TXN_FORM_LOAD_TXN_SUCCESS:
            return {
                ...state,
                showEditTransaction: true,
                currentTransaction: action.payload,
                currentSecuritySelection: {
                    ...state.currentSecuritySelection,
                    securitySelected: true,
                }
            }
        case TXN_FORM_EDIT_TXN_SUCCESS:
            txns = state.transactions.filter( o => o.key !== action.payload.key);
            txns.push(action.payload);
            return {
                ...state,
                transactions: txns
            }

        case RESET_TXN_STATE_REQUEST:
            return DEFAULT_STATE;

        case SEARCH_SECURITY_REQUEST:
            return {
                ...state,
                currentSecuritySelection: {
                    ...state.currentSecuritySelection,
                    securitySelected: false,
                    security: '',
                }
            };

        case SEARCH_SECURITY_SUCCESS:
            let results = [];
            action.payload.map( ({name}) => {
                results = results.concat(name)
            });

            return {
                ...state,
                currentSecuritySelection: {
                    ...state.currentSecuritySelection,
                    securities: results
                }
            }

        case SEARCH_SECURITY_FAILURE:
            return state;

        case SELECT_EXCHANGE_REQUEST:
            return {
                ...state,
                currentSecuritySelection: {
                    securities: [],
                    exchange: action.payload
                }
            }
        
        case SELECT_SECURITY_REQUEST:
            return {
                ...state,
                currentSecuritySelection: {
                    ...state.currentSecuritySelection,
                    securitySelected: true,
                    security: action.payload,
                }
            }
            
        default: return state;
    }
}
