import React, { Component }  from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { Icon, Spin } from 'antd';
import Login from '../pages/Login';
import HeaderContentFooter from '../layouts/HeaderContentFooter';

//Import Actions
import { checkToken } from '../services/User/actions';


class Home extends Component {
  componentWillMount() {
    this.props.checkToken();
  } 

  
  render() {
    if ( this.props.User.checkTokenLoading ) {
    //if ( true ) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems:' center', height:'100vh'}}>
            
            <Spin tip='Loading...' />
        </div>
      )
    }
    else {
      if ( !this.props.User.isValidUser ) {
        return ( 
          <Login />
        )
      }
      else
        return (
          <HeaderContentFooter />
        )
    }
    
  }
}



const mapStateToProps = state => ({
  User: state.User,
});

const mapDispatchToProps = {
    checkToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
