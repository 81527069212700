import {
    GET_FACTSHEET_REQUEST,
    FACTSHEET_SEARCH_REQUEST,
    LOAD_FACTSHEET_COMPANY_LIST_REQUEST,
    SHOW_ADD_NOTES_FORM,
    SHOW_EDIT_NOTES_FORM,
    ADD_NOTES_REQUEST,
    EDIT_NOTES_REQUEST,
    DELETE_NOTES_REQUEST,
    LOAD_NOTES_REQUEST,
} from './types';

export const getFactsheet = (ticker) => ({
    type: GET_FACTSHEET_REQUEST,
    ticker: String(ticker).toUpperCase()
});

// export const factsheetSearch = (security) => ({
//     type: FACTSHEET_SEARCH_REQUEST,
//     security: String(security).toUpperCase()
// });

// export const loadFactsheetCompanyList = () => ({
//     type: LOAD_FACTSHEET_COMPANY_LIST_REQUEST,
// });

export const showAddNotes = (show) => ({
    type: SHOW_ADD_NOTES_FORM,
    payload: show
});

export const showEditNotes = (show) => ({
    type: SHOW_EDIT_NOTES_FORM,
    payload: show
});

export const addNotes = (notes) => ({
    type: ADD_NOTES_REQUEST,
    payload: notes
});

export const editNotes = (notes) => ({
    type: EDIT_NOTES_REQUEST,
    payload: notes
});

export const deleteNotes = (id) => ({
    type: DELETE_NOTES_REQUEST,
    payload: id
});

export const loadNotes = (id) => ({
    type: LOAD_NOTES_REQUEST,
    payload: id
});