import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    LOAD_USER_LIST_REQUEST,
    LOAD_USER_LIST_SUCCESS,
    LOAD_USER_LIST_FAILURE,
    LOAD_PRICES_LAST_REFRESH_DATE_FAILURE,
    LOAD_PRICES_LAST_REFRESH_DATE_SUCCESS,
    LOAD_PRICES_LAST_REFRESH_DATE_REQUEST,    
} from './types';
import axios from 'axios';


function* loadUserList(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/users`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_USER_LIST_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_USER_LIST_FAILURE });
    }
}

function* loadPricesLastRefreshDate(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/pricesLastRefreshDate`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_PRICES_LAST_REFRESH_DATE_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_PRICES_LAST_REFRESH_DATE_FAILURE });
    }
}



export default function* () {
    yield takeLatest(LOAD_USER_LIST_REQUEST, loadUserList);
    yield takeLatest(LOAD_PRICES_LAST_REFRESH_DATE_REQUEST, loadPricesLastRefreshDate);
};
