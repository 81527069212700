import {
    LOAD_XIRR_REQUEST,
    LOAD_XIRR_SUCCESS,
    LOAD_XIRR_FAILURE,
    LOAD_TSPAN_REQUEST,
    LOAD_TSPAN_SUCCESS,
    LOAD_TSPAN_FAILURE,
    LOAD_PLTSPAN_REQUEST,
    LOAD_PLTSPAN_SUCCESS,
    LOAD_PLTSPAN_FAILURE,
    LOAD_PERF_METRICS_REQUEST,
    LOAD_PERF_METRICS_SUCCESS,
    LOAD_PERF_METRICS_FAILURE,
    LOAD_PLBREAKDOWN_REQUEST,
    LOAD_PLBREAKDOWN_SUCCESS,
    LOAD_PLBREAKDOWN_FAILURE,
    LOAD_PLBREAKDOWN_PCT_REQUEST,
    LOAD_PLBREAKDOWN_PCT_SUCCESS,
    LOAD_PLBREAKDOWN_PCT_FAILURE,
    LOAD_EXP_METRICS_REQUEST,
    LOAD_EXP_METRICS_SUCCESS,
    LOAD_EXP_METRICS_FAILURE,
    LOAD_MV_VS_INJECTION_REQUEST,
    LOAD_MV_VS_INJECTION_SUCCESS,
    LOAD_MV_VS_INJECTION_FAILURE,
    LOAD_EXP_BREAKDOWN_REQUEST,
    LOAD_EXP_BREAKDOWN_SUCCESS,
    LOAD_EXP_BREAKDOWN_FAILURE,
    LOAD_RECENT_DIVIDENDS_REQUEST,
    LOAD_RECENT_DIVIDENDS_SUCCESS,
    LOAD_RECENT_DIVIDENDS_FAILURE,
    LOAD_DIVIDENDS_METRICS_REQUEST,
    LOAD_DIVIDENDS_METRICS_SUCCESS,
    LOAD_DIVIDENDS_METRICS_FAILURE,
    LOAD_DIVIDENDS_LTM_REQUEST,
    LOAD_DIVIDENDS_LTM_SUCCESS,
    LOAD_DIVIDENDS_LTM_FAILURE,
    LOAD_DIVIDENDS_YEARLY_REQUEST,
    LOAD_DIVIDENDS_YEARLY_SUCCESS,
    LOAD_DIVIDENDS_YEARLY_FAILURE,
    LOAD_EXP_GROUPED_BY_COUNTRY_REQUEST,
    LOAD_EXP_GROUPED_BY_COUNTRY_SUCCESS,
    LOAD_EXP_GROUPED_BY_COUNTRY_FAILURE,
    LOAD_EXP_GROUPED_BY_SECTOR_REQUEST,
    LOAD_EXP_GROUPED_BY_SECTOR_SUCCESS,
    LOAD_EXP_GROUPED_BY_SECTOR_FAILURE,
    LOAD_EXP_GROUPED_BY_INDUSTRY_REQUEST,
    LOAD_EXP_GROUPED_BY_INDUSTRY_SUCCESS,
    LOAD_EXP_GROUPED_BY_INDUSTRY_FAILURE,
    LOAD_XIRR_BENCHMARK_REQUEST,
    LOAD_XIRR_BENCHMARK_SUCCESS,
    LOAD_XIRR_BENCHMARK_FAILURE,
    LOAD_PORTFOLIO_SECURITIES_REQUEST,
    LOAD_PORTFOLIO_SECURITIES_SUCCESS,
    LOAD_PORTFOLIO_SECURITIES_FAILURE,
    FILTER_EXP_SECTOR_BREAKDOWN_REQUEST,
    FILTER_EXP_COUNTRY_BREAKDOWN_REQUEST,
    FILTER_EXP_INDUSTRY_BREAKDOWN_REQUEST,
    FILTER_EXP_CLEAR_REQUEST,
} from './types';

import { sumBy, filterBy } from '../../common/helper';

const DEFAULT_STATE = {
    isLoading: {
        xirr: false,
        tspan: false,
        PLtspan: false,
        perfMetrics: false,
        PLBreakdown: false,
        PLBreakdownPCT: false,
        MVvsInjectionOverTime: false,
        exposureBreakdown: false,
        recentDividends: false,
        dividendsMetrics: false,
        dividendsLTM: false,
        dividendsYearly: false,
        expGroupedByCountry: false,
        expGroupedBySector: false,
        expGroupedByIndustry: false,
        xirrBenchmark: false,
        portfolioSecurities: false,
    },
    dividends: {
        market_value:0,
        injection:0,
        total_dividends:0,
        total_dividends_ltm:0,
        avg_dividends:0,
        yield_on_mv:0,
        yield_on_cost:0,
        dividendsLTM:[],
        dividendsYearly:[],
    },
    performance: {
        xirr: 0,
        xirrBenchmark: 0,
        tspan: [],
        PLtspan: [],
        mv: 0,
        injection: 0,
        dividends: 0,
        pl: 0,
        mv_gainloss: 0,
        moc: 0,
        PLBreakdown: [],
        PLBreakdownPCT: [],
        expense_ratio:0,
    },
    
    exposure: {
        mv: 0,
        injection:0,
        fees:0,
        cash:0,
        MVvsInjectionOverTime: [],
        exposureBreakdown: [],
        exposureBreakdownFiltered: [],
        exposureBreakdownFilteredFlag: false,
        expGroupedByCountry: [],
        expGroupedBySector: [],
        expGroupedByIndustry: [],
    },
    portfolioSecurities: {

    }
};


export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {

        case LOAD_XIRR_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    xirr: true
                }
            };

        case LOAD_XIRR_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    xirr: false
                },
                performance: {
                    ...state.performance,
                    xirr: action.payload
                }
            };

        case LOAD_XIRR_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    xirr: false
                }
            };


        case LOAD_TSPAN_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    tspan: true
                }
            };

        case LOAD_TSPAN_SUCCESS:
            var tspan_sic = 0;
            for (var i=0;i<action.payload.length;i++) {
                if ( action.payload[i].timespan === 'Since Inc' ) {
                    tspan_sic = action.payload[i].ror;
                    break;
                }
            }
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    tspan: false
                },
                performance: {
                    ...state.performance,
                    tspan: action.payload,
                    tspan_sic: tspan_sic
                }
            };

        case LOAD_TSPAN_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    tspan: false
                }
            };


            case LOAD_PLTSPAN_REQUEST:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLtspan: true
                    }
                };
    
            case LOAD_PLTSPAN_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLtspan: false
                    },
                    performance: {
                        ...state.performance,
                        PLtspan: action.payload,
                    }
                };
    
            case LOAD_PLTSPAN_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLtspan: false
                    }
                };

            case LOAD_PERF_METRICS_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            perfMetrics: true
                        }
                    };
        
            case LOAD_PERF_METRICS_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        perfMetrics: false
                    },
                    performance: {
                        ...state.performance,
                        ...action.payload,
                    }
                };
    
            case LOAD_PERF_METRICS_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        perfMetrics: false
                    }
                };

            case LOAD_PLBREAKDOWN_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            PLBreakdown: true
                        }
                    };
        
            case LOAD_PLBREAKDOWN_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLBreakdown: false
                    },
                    performance: {
                        ...state.performance,
                        PLBreakdown: action.payload,
                    }
                };
    
            case LOAD_PLBREAKDOWN_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLBreakdown: false
                    }
                };

            case LOAD_PLBREAKDOWN_PCT_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            PLBreakdownPCT: true
                        }
                    };
        
            case LOAD_PLBREAKDOWN_PCT_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLBreakdownPCT: false
                    },
                    performance: {
                        ...state.performance,
                        PLBreakdownPCT: action.payload,
                    }
                };
    
            case LOAD_PLBREAKDOWN_PCT_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        PLBreakdownPCT: false
                    }
            };

            case LOAD_EXP_METRICS_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            expMetrics: true
                        }
                    };
        
            case LOAD_EXP_METRICS_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expMetrics: false
                    },
                    exposure: {
                        ...state.exposure,
                        ...action.payload,
                    }
                };
    
            case LOAD_EXP_METRICS_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expMetrics: false
                    }
            };

            case LOAD_MV_VS_INJECTION_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            MVvsInjectionOverTime: true
                        }
                    };
        
            case LOAD_MV_VS_INJECTION_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        MVvsInjectionOverTime: false
                    },
                    exposure: {
                        ...state.exposure,
                        MVvsInjectionOverTime: action.payload,
                    }
                };
    
            case LOAD_MV_VS_INJECTION_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        MVvsInjectionOverTime: false
                    }
            };

            case LOAD_EXP_BREAKDOWN_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            exposureBreakdown: true
                        },
                        exposure: {
                            ...state.exposure,
                            exposureBreakdownFilteredFlag: false,
                        }
                    };
        
            case LOAD_EXP_BREAKDOWN_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        exposureBreakdown: false
                    },
                    exposure: {
                        ...state.exposure,
                        exposureBreakdown: action.payload,
                        exposureBreakdownFiltered: action.payload,
                        exposureBreakdownFilteredFlag: false,
                        expGroupedByCountry: sumBy(action.payload, 'country', 'market value'),
                        expGroupedBySector: sumBy(action.payload, 'sector', 'market value'),
                        expGroupedByIndustry: sumBy(action.payload, 'industry', 'market value'),
                    }
                };
    
            case LOAD_EXP_BREAKDOWN_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        exposureBreakdown: false,
                    },
                    exposure: {
                        ...state.exposure,
                        exposureBreakdownFilteredFlag: false,
                    }
            };

            case LOAD_RECENT_DIVIDENDS_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            recentDividends: true
                        }
                    };
        
            case LOAD_RECENT_DIVIDENDS_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        recentDividends: false
                    },
                    dividends: {
                        ...state.dividends,
                        recentDividends: action.payload,
                    }
                };
    
            case LOAD_RECENT_DIVIDENDS_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        recentDividends: false
                    }
            };

            case LOAD_DIVIDENDS_METRICS_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            dividendsMetrics: true
                        }
                    };
        
            case LOAD_DIVIDENDS_METRICS_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        dividendsMetrics: false
                    },
                    dividends: {
                        ...state.dividends,
                        ...action.payload,
                    }
                };
    
            case LOAD_DIVIDENDS_METRICS_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        dividendsMetrics: false
                    }
            };

            case LOAD_DIVIDENDS_LTM_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            dividendsLTM: true
                        }
                    };
        
            case LOAD_DIVIDENDS_LTM_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        dividendsLTM: false
                    },
                    dividends: {
                        ...state.dividends,
                        dividendsLTM: action.payload,
                    }
                };
    
            case LOAD_DIVIDENDS_LTM_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        dividendsLTM: false
                    }
            };

            case LOAD_DIVIDENDS_YEARLY_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            dividendsYearly: true
                        }
                    };
        
            case LOAD_DIVIDENDS_YEARLY_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        dividendsYearly: false
                    },
                    dividends: {
                        ...state.dividends,
                        dividendsYearly: action.payload,
                    }
                };
    
            case LOAD_DIVIDENDS_YEARLY_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        dividendsYearly: false
                    }
            };

            case LOAD_EXP_GROUPED_BY_COUNTRY_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            expGroupedByCountry: true
                        }
                    };
        
            case LOAD_EXP_GROUPED_BY_COUNTRY_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expGroupedByCountry: false
                    },
                    exposure: {
                        ...state.exposure,
                        expGroupedByCountry: action.payload,
                    }
                };
    
            case LOAD_EXP_GROUPED_BY_COUNTRY_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expGroupedByCountry: false
                    }
            };

            case LOAD_EXP_GROUPED_BY_SECTOR_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            expGroupedBySector: true
                        }
                    };
        
            case LOAD_EXP_GROUPED_BY_SECTOR_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expGroupedBySector: false
                    },
                    exposure: {
                        ...state.exposure,
                        expGroupedBySector: action.payload,
                    }
                };
    
            case LOAD_EXP_GROUPED_BY_SECTOR_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expGroupedBySector: false
                    }
            };

            case LOAD_EXP_GROUPED_BY_INDUSTRY_REQUEST:
                    return {
                        ...state,
                        isLoading: {
                            ...state.isLoading,
                            expGroupedByIndustry: true
                        }
                    };
        
            case LOAD_EXP_GROUPED_BY_INDUSTRY_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expGroupedByIndustry: false
                    },
                    exposure: {
                        ...state.exposure,
                        expGroupedByIndustry: action.payload,
                    }
                };
    
            case LOAD_EXP_GROUPED_BY_INDUSTRY_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        expGroupedByIndustry: false
                    }
            };

            case LOAD_XIRR_BENCHMARK_REQUEST:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        xirrBenchmark: true
                    }
                };

            case LOAD_XIRR_BENCHMARK_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        xirrBenchmark: false
                    },
                    performance: {
                        ...state.performance,
                        xirrBenchmark: action.payload
                    }
                };

            case LOAD_XIRR_BENCHMARK_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        xirrBenchmark: false
                    }
                };

            case LOAD_PORTFOLIO_SECURITIES_REQUEST:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        portfolioSecurities: true
                    }
                };
            
            case LOAD_PORTFOLIO_SECURITIES_SUCCESS:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        portfolioSecurities: false
                    },
                    portfolioSecurities: action.payload
                };
            
            case LOAD_PORTFOLIO_SECURITIES_FAILURE:
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        portfolioSecurities: false
                    },
                    //portfolioSecurities: {}
                };

            case FILTER_EXP_SECTOR_BREAKDOWN_REQUEST:
                var exposureBreakdownFiltered = filterBy(state.exposure.exposureBreakdownFiltered, 'sector', action.filter)
                return {
                    ...state,
                    exposure: {
                        ...state.exposure,
                        exposureBreakdownFiltered: exposureBreakdownFiltered,
                        exposureBreakdownFilteredFlag: true,
                        expGroupedByCountry: sumBy(exposureBreakdownFiltered, 'country', 'market value'),
                        expGroupedBySector: sumBy(exposureBreakdownFiltered, 'sector', 'market value'),
                        expGroupedByIndustry: sumBy(exposureBreakdownFiltered, 'industry', 'market value'),
                    }
                };


            case FILTER_EXP_COUNTRY_BREAKDOWN_REQUEST:
                var exposureBreakdownFiltered = filterBy(state.exposure.exposureBreakdownFiltered, 'country', action.filter)
                return {
                    ...state,
                    exposure: {
                        ...state.exposure,
                        exposureBreakdownFiltered: exposureBreakdownFiltered,
                        exposureBreakdownFilteredFlag: true,
                        expGroupedByCountry: sumBy(exposureBreakdownFiltered, 'country', 'market value'),
                        expGroupedBySector: sumBy(exposureBreakdownFiltered, 'sector', 'market value'),
                        expGroupedByIndustry: sumBy(exposureBreakdownFiltered, 'industry', 'market value'),
                    }
                };


            case FILTER_EXP_INDUSTRY_BREAKDOWN_REQUEST:
                var exposureBreakdownFiltered = filterBy(state.exposure.exposureBreakdownFiltered, 'industry', action.filter)
                return {
                    ...state,                    
                    exposure: {
                        ...state.exposure,                        
                        exposureBreakdownFiltered: exposureBreakdownFiltered,
                        exposureBreakdownFilteredFlag: true,
                        expGroupedByCountry: sumBy(exposureBreakdownFiltered, 'country', 'market value'),
                        expGroupedBySector: sumBy(exposureBreakdownFiltered, 'sector', 'market value'),
                        expGroupedByIndustry: sumBy(exposureBreakdownFiltered, 'industry', 'market value'),
                    }
                };

            case FILTER_EXP_CLEAR_REQUEST:
                var exposureBreakdownFiltered = state.exposure.exposureBreakdown;
                return {
                    ...state,                    
                    exposure: {
                        ...state.exposure,                        
                        exposureBreakdownFiltered: exposureBreakdownFiltered,
                        exposureBreakdownFilteredFlag: false,
                        expGroupedByCountry: sumBy(exposureBreakdownFiltered, 'country', 'market value'),
                        expGroupedBySector: sumBy(exposureBreakdownFiltered, 'sector', 'market value'),
                        expGroupedByIndustry: sumBy(exposureBreakdownFiltered, 'industry', 'market value'),
                    }
                };

                
    

        default: 
            return state;
    }
};
