import {
    AUTH_REQUEST,
    LOGOUT_REQUEST,
    CHECK_TOKEN_REQUEST,
} from './types';

export const auth = (username, password, authType, GoogleIDToken) => ({
    type: AUTH_REQUEST,
    payload: { 
        username, 
        password,
        authType,
        GoogleIDToken
    }
});

export const logout = () => ({
    type: LOGOUT_REQUEST
});

export const checkToken = () => ({
    type: CHECK_TOKEN_REQUEST,
    payload: { token: window.localStorage.token }
});
