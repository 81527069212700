import {
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
    GET_NOTIFICATIONS_REQUEST,
    RESET_NOTIFICATIONS_STATE_REQUEST
} from './types';

const DEFAULT_STATE = {
    notifications: []
};

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST: 
            return state;

        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload
            }

        case GET_NOTIFICATIONS_FAILURE:
            return state;

        case RESET_NOTIFICATIONS_STATE_REQUEST:
            return DEFAULT_STATE;

        default: return state;
    }
}
