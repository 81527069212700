import Transactions from './Transactions/reducers';
import Securities from './Securities/reducers';
import User from './User/reducers';
import Notifications from './Notifications/reducers';
import Reporting from './Reporting/reducers';
import Factsheet from './Factsheet/reducers';
import Admin from './Admin/reducers';

export default {
    Transactions,
    Securities,
    User,
    Notifications,
    Reporting,
    Factsheet,
    Admin
};
