import {
    GET_FACTSHEET_SUCCESS,
    GET_FACTSHEET_FAILURE,
    GET_FACTSHEET_REQUEST,
    FACTSHEET_SEARCH_FAILURE,
    FACTSHEET_SEARCH_SUCCESS,
    FACTSHEET_SEARCH_REQUEST,
    LOAD_FACTSHEET_COMPANY_LIST_FAILURE,
    LOAD_FACTSHEET_COMPANY_LIST_SUCCESS,
    LOAD_FACTSHEET_COMPANY_LIST_REQUEST,
    LOAD_FACTSHEET_HISTORICAL_PRICE_FAILURE,
    LOAD_FACTSHEET_HISTORICAL_PRICE_SUCCESS,
    LOAD_FACTSHEET_XIRR_SUCCESS,
    LOAD_FACTSHEET_XIRR_FAILURE,
    LOAD_FACTSHEET_NOTES_BY_SECURITY_FAILURE,
    LOAD_FACTSHEET_NOTES_BY_SECURITY_SUCCESS,
    LOAD_FACTSHEET_NOTES_BY_SECURITY_REQUEST,
    SHOW_ADD_NOTES_FORM,
    SHOW_EDIT_NOTES_FORM,
    ADD_NOTES_SUCCESS,
    EDIT_NOTES_SUCCESS,
    DELETE_NOTES_SUCCESS,
    LOAD_NOTES_REQUEST,
} from './types';

const DEFAULT_STATE = {
    name: '',
    ticker: '',
    isLoading: false,
    isLoadingFactsheetHistoricalPrice: false,
    isLoadingXIRR: false,
    isLoadingNotes: false,
    notes: [],
    search: [],
    factsheetCompanyList: [],
    factsheetHistoricalPrice: [],
    xirr: 0,
    showAddNotes: false,
    showEditNotes: false,
    currentNote: {
        id: '',
        name: '',
        date: '',
        content: '',
        shared: '',
        reminder: ''
    }
};

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case LOAD_NOTES_REQUEST:
            var currentNote = state.notes.filter( o => o.id === action.payload );
            return {
                ...state,
                currentNote: currentNote[0]
            };
        
        case EDIT_NOTES_SUCCESS:    
            notes = state.notes.filter( o => o.id !== action.payload.id);
            notes.push(action.payload);
            notes.sort( (a,b) => {return new Date(a.date) - new Date(b.date)});
            return {
                ...state,
                notes: notes
            };

        case DELETE_NOTES_SUCCESS:
            notes = state.notes.filter( o => o.id !== action.payload)
            return {
                ...state,
                notes: notes
            };
            
        case ADD_NOTES_SUCCESS:
            var notes = state.notes;
            notes.push(action.payload);
            return {
                ...state,
                notes: notes
            };
        
        case SHOW_EDIT_NOTES_FORM:
            return {
                ...state,
                showEditNotes: action.payload
            };
            
        case SHOW_ADD_NOTES_FORM:
            return {
                ...state,
                showAddNotes: action.payload
            };

        case LOAD_FACTSHEET_XIRR_SUCCESS:
            return {
                ...state,
                isLoadingXIRR: false,
                xirr: action.payload
            };
        
        case LOAD_FACTSHEET_XIRR_FAILURE:
            return {
                ...state,
                isLoadingXIRR: false
            };
                    
        case LOAD_FACTSHEET_HISTORICAL_PRICE_SUCCESS:
            return {
                ...state,
                isLoadingFactsheetHistoricalPrice: false,
                factsheetHistoricalPrice: action.payload
            };
        
        case LOAD_FACTSHEET_HISTORICAL_PRICE_FAILURE:
            return {
                ...state,
                isLoadingFactsheetHistoricalPrice: false
            };

        case GET_FACTSHEET_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                ...action.payload
            };

        case GET_FACTSHEET_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        case GET_FACTSHEET_REQUEST:
            return {
                ...state,
                isLoading: true,
                isLoadingFactsheetHistoricalPrice: true,
                isLoadingXIRR: true,
                isLoadingNotes: true,
            };

        case FACTSHEET_SEARCH_REQUEST:
            return state;

        case FACTSHEET_SEARCH_SUCCESS:
            let results = [];
            action.payload.map( ({name}) => {
                results = results.concat(name)
            });

            return {
                ...state,
                search: results
            }

        case FACTSHEET_SEARCH_FAILURE:
            return state;

        case LOAD_FACTSHEET_COMPANY_LIST_SUCCESS: 
            return {
                ...state,
                factsheetCompanyList: action.payload
            };

        case LOAD_FACTSHEET_COMPANY_LIST_FAILURE:
            return {
                ...state,
                factsheetCompanyList: []
            }

        case LOAD_FACTSHEET_COMPANY_LIST_REQUEST:
            return {
                ...state,
                factsheetCompanyList: []
            };

        case LOAD_FACTSHEET_NOTES_BY_SECURITY_FAILURE:
            return {
                ...state,
                isLoadingNotes: false,
                notes: []
            };

        case LOAD_FACTSHEET_NOTES_BY_SECURITY_SUCCESS:
            return {
                ...state,
                isLoadingNotes: false,
                notes: action.payload
            };

        
        default: return state;
    }
}
