import Transactions from './Transactions/sagas';
import Securities from './Securities/sagas';
import User from './User/sagas';
import Notifications from './Notifications/sagas';
import Reporting from './Reporting/sagas';
import Factsheet from './Factsheet/sagas';
import Admin from './Admin/sagas';

//import firebase from 'firebase';

// var config = {
//     apiKey: "AIzaSyAdSBiRO7nhQ1dcz1me8gYC6YWaIBZgIC4",
//     authDomain: "portfolio-analytics-140f8.firebaseapp.com",
//     databaseURL: "https://portfolio-analytics-140f8.firebaseio.com",
//     projectId: "portfolio-analytics-140f8",
//     storageBucket: "portfolio-analytics-140f8.appspot.com",
//     messagingSenderId: "975158305366"
// };
// firebase.initializeApp(config);

export default [
    Transactions,
    Securities,
    User,
    Notifications,
    Reporting,
    Factsheet,
    Admin,
];
