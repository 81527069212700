import {
    LOAD_SECURITY_LIST_REQUEST,
    LOAD_SECURITY_LIST_SUCCESS,
    LOAD_SECURITY_LIST_FAILURE,
    ADD_SECURITY_TICKER_MAPPING_REQUEST,
    ADD_SECURITY_TICKER_MAPPING_SUCCESS,
    ADD_SECURITY_TICKER_MAPPING_FAILURE,
    SHOW_ADD_SECURITY_TICKER_MAPPING_FORM,
    SHOW_EDIT_SECURITY_TICKER_MAPPING_FORM,
    EDIT_SECURITY_TICKER_MAPPING_SUCCESS,
    LOAD_SECURITY_TICKER_MAPPING_SUCCESS,
    LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST,
    LOAD_ALL_SECURITY_TICKER_MAPPING_SUCCESS,
    LOAD_ALL_SECURITY_TICKER_MAPPING_FAILURE,
    DELETE_SECURITY_TICKER_MAPPING_SUCCESS,
    RESET_SECURITY_STATE_REQUEST,
    LOAD_EXCHANGES_REQUEST,
    LOAD_EXCHANGES_SUCCESS,
    LOAD_EXCHANGES_FAILURE,
} from './types';

const DEFAULT_STATE = {
    securities: [],
    exchanges: [],
    showAddSecurityTickerMapping: false,
    showEditSecurityTickerMapping: false,
    securityTickerMappings: [],
    currentSecurityTickerMappings: [],
    loadingSecurityTickerMappings: false,
};

var tmpSecurityTickerMappings = [];

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case LOAD_EXCHANGES_REQUEST:
            return state;
        case LOAD_EXCHANGES_SUCCESS:
            return {
                ...state,
                exchanges: action.payload
            }
        case LOAD_EXCHANGES_FAILURE:
            return state;
        case ADD_SECURITY_TICKER_MAPPING_REQUEST:
            return state;
        case ADD_SECURITY_TICKER_MAPPING_SUCCESS:
            tmpSecurityTickerMappings = state.securityTickerMappings;
            tmpSecurityTickerMappings.push(action.payload);
            return {
                ...state,
                securityTickerMappings: tmpSecurityTickerMappings
            }
        case ADD_SECURITY_TICKER_MAPPING_FAILURE:
            return state;

        
        case LOAD_SECURITY_TICKER_MAPPING_SUCCESS:
            return {
                ...state,
                currentSecurityTickerMappings: action.payload,
                showEditSecurityTickerMapping: true
            }



        case LOAD_ALL_SECURITY_TICKER_MAPPING_REQUEST:
            return {
                ...state,
                loadingSecurityTickerMappings: true
            }
        case LOAD_ALL_SECURITY_TICKER_MAPPING_SUCCESS:
            return {
                ...state,
                securityTickerMappings: action.payload,
                loadingSecurityTickerMappings: false
            }
        case LOAD_ALL_SECURITY_TICKER_MAPPING_FAILURE:
            return {
                ...state,
                loadingSecurityTickerMappings: false
            }

        case DELETE_SECURITY_TICKER_MAPPING_SUCCESS:
            tmpSecurityTickerMappings = state.securityTickerMappings.filter( o => o.key !== action.payload);
            return {
                ...state,
                securityTickerMappings: tmpSecurityTickerMappings
            }

        case EDIT_SECURITY_TICKER_MAPPING_SUCCESS:
            tmpSecurityTickerMappings = state.securityTickerMappings.filter( o => o.key !== action.payload.key);
            tmpSecurityTickerMappings.push(action.payload);
            return {
                ...state,
                securityTickerMappings: tmpSecurityTickerMappings,
                //showEditSecurityTickerMapping: false
            }
        case SHOW_ADD_SECURITY_TICKER_MAPPING_FORM:
            return {
                ...state,
                showAddSecurityTickerMapping: action.payload
            };
        case SHOW_EDIT_SECURITY_TICKER_MAPPING_FORM:
            return {
                ...state,
                showEditSecurityTickerMapping: action.payload
            };
        case LOAD_SECURITY_LIST_SUCCESS:
            return {
                ...state,
                securities: action.payload
            };
        case LOAD_SECURITY_LIST_REQUEST:
            return state;
        
        case LOAD_SECURITY_LIST_FAILURE:
            return state;

        case RESET_SECURITY_STATE_REQUEST:
            return DEFAULT_STATE;

        default: 
            return state;
    }
};
