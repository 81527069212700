


export default {
    //API_URL: 'https://api.xscript.dev',
    //API_URL: 'http://localhost:5001/cloud-api-c7f02/us-central1',    
    //API_URL: process.env.NODE_ENV === 'production' ? 'https://api.xscript.dev' : 'http://localhost:5001/cloud-api-c7f02/us-central1',
    // LAMBDA_URL: process.env.NODE_ENV === 'production' ? 'https://kpa04f1o9f.execute-api.ap-southeast-1.amazonaws.com/Prod' : 'http://localhost:5002',
    LAMBDA_URL: 'https://7urmgnw2yi.execute-api.ap-southeast-1.amazonaws.com/Prod',
    LOCAL_URL: 'http://localhost:5002',
}