//import libraries
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';


const Transactions = React.lazy( () => import('./pages/Transactions'));
const SecurityTickerMapping = React.lazy( () => import('./pages/SecurityTickerMapping'));
const Factsheet = React.lazy( () => import ('./pages/Factsheet'));
const Portfolio = React.lazy( () => import ('./pages/Portfolio'));
const Resize = React.lazy( () => import('./pages/Resize'));
const Admin = React.lazy( () => import('./pages/Admin'));
const Prototype = React.lazy( () => import('./pages/Prototype'));

// create a component
class routes extends Component {

    render(){
        return (
            <React.Suspense fallback={<span>Loading...</span>} >
                <Switch>
                    <Route exact path="/" component={Portfolio} />
                    <Route exact path="/prototype" component={Prototype} />
                    <Route exact path="/portfolio" component={Portfolio} />
                    <Route exact path="/transactions" component={Transactions} />
                    <Route exact path="/security-ticker-mapping" component={SecurityTickerMapping} />
                    <Route exact path="/factsheet" component={Factsheet} />
                    <Route exact path="/resize" component={Resize} />
                    <Route exact path="/admin" component={Admin} />
                    {/*<Route exact path="/performance" component={Performance} />
                    <Route exact path="/exposure" component={Exposure} />
                    <Route exact path="/dividend" component={Dividend} />*/}
                </Switch>
            </React.Suspense>
        );
    }
}


export default routes;

