import config from '../../config';
import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
    LOAD_XIRR_REQUEST,
    LOAD_XIRR_SUCCESS,
    LOAD_XIRR_FAILURE,
    LOAD_TSPAN_REQUEST,
    LOAD_TSPAN_SUCCESS,
    LOAD_TSPAN_FAILURE,
    LOAD_PLTSPAN_REQUEST,
    LOAD_PLTSPAN_SUCCESS,
    LOAD_PLTSPAN_FAILURE,
    LOAD_PERF_METRICS_REQUEST,
    LOAD_PERF_METRICS_SUCCESS,
    LOAD_PERF_METRICS_FAILURE,
    LOAD_PLBREAKDOWN_REQUEST,
    LOAD_PLBREAKDOWN_SUCCESS,
    LOAD_PLBREAKDOWN_FAILURE,
    LOAD_PLBREAKDOWN_PCT_REQUEST,
    LOAD_PLBREAKDOWN_PCT_SUCCESS,
    LOAD_PLBREAKDOWN_PCT_FAILURE,
    LOAD_EXP_METRICS_REQUEST,
    LOAD_EXP_METRICS_SUCCESS,
    LOAD_EXP_METRICS_FAILURE,
    LOAD_MV_VS_INJECTION_REQUEST,
    LOAD_MV_VS_INJECTION_SUCCESS,
    LOAD_MV_VS_INJECTION_FAILURE,
    LOAD_EXP_BREAKDOWN_REQUEST,
    LOAD_EXP_BREAKDOWN_SUCCESS,
    LOAD_EXP_BREAKDOWN_FAILURE,
    LOAD_RECENT_DIVIDENDS_REQUEST,
    LOAD_RECENT_DIVIDENDS_SUCCESS,
    LOAD_RECENT_DIVIDENDS_FAILURE,
    LOAD_DIVIDENDS_METRICS_REQUEST,
    LOAD_DIVIDENDS_METRICS_SUCCESS,
    LOAD_DIVIDENDS_METRICS_FAILURE,
    LOAD_DIVIDENDS_LTM_REQUEST,
    LOAD_DIVIDENDS_LTM_SUCCESS,
    LOAD_DIVIDENDS_LTM_FAILURE,
    LOAD_DIVIDENDS_YEARLY_REQUEST,
    LOAD_DIVIDENDS_YEARLY_SUCCESS,
    LOAD_DIVIDENDS_YEARLY_FAILURE,
    LOAD_EXP_GROUPED_BY_COUNTRY_REQUEST,
    LOAD_EXP_GROUPED_BY_COUNTRY_SUCCESS,
    LOAD_EXP_GROUPED_BY_COUNTRY_FAILURE,
    LOAD_EXP_GROUPED_BY_SECTOR_REQUEST,
    LOAD_EXP_GROUPED_BY_SECTOR_SUCCESS,
    LOAD_EXP_GROUPED_BY_SECTOR_FAILURE,
    LOAD_EXP_GROUPED_BY_INDUSTRY_REQUEST,
    LOAD_EXP_GROUPED_BY_INDUSTRY_SUCCESS,
    LOAD_EXP_GROUPED_BY_INDUSTRY_FAILURE,
    LOAD_XIRR_BENCHMARK_REQUEST,
    LOAD_XIRR_BENCHMARK_SUCCESS,
    LOAD_XIRR_BENCHMARK_FAILURE,
    LOAD_PORTFOLIO_SECURITIES_REQUEST,
    LOAD_PORTFOLIO_SECURITIES_SUCCESS,
    LOAD_PORTFOLIO_SECURITIES_FAILURE,
} from './types';

function* loadXIRR() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/xirr`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_XIRR_SUCCESS,
            payload: ret.data.xirr
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_XIRR_FAILURE
        })
    }
}

function* loadTSpan() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/tspan`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_TSPAN_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_TSPAN_FAILURE
        })
    }
}

function* loadPLTSpan() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/PLtspan`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_PLTSPAN_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_PLTSPAN_FAILURE
        })
    }
}

function* loadPerfMetrics() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/perfMetrics`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_PERF_METRICS_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_PERF_METRICS_FAILURE
        })
    }
}

function* loadPLBreakdown() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/PLBreakdown`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_PLBREAKDOWN_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_PLBREAKDOWN_FAILURE
        })
    }
}

function* loadPLBreakdownPCT() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/PLBreakdownPCT`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_PLBREAKDOWN_PCT_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_PLBREAKDOWN_PCT_FAILURE
        })
    }
}

function* loadExpMetrics() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/expMetrics`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_EXP_METRICS_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_EXP_METRICS_FAILURE
        })
    }
}

function* loadMVvsInjection() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/MVvsInjectionOverTime`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_MV_VS_INJECTION_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_MV_VS_INJECTION_FAILURE
        })
    }
}

function* loadExposureBreakdown() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/exposureBreakdown`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_EXP_BREAKDOWN_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_EXP_BREAKDOWN_FAILURE
        })
    }
}

function* loadRecentDividends() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/recentDividends`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_RECENT_DIVIDENDS_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_RECENT_DIVIDENDS_FAILURE
        })
    }
}

function* loadDividendsMetrics() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/dividendsMetrics`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_DIVIDENDS_METRICS_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_DIVIDENDS_METRICS_FAILURE
        })
    }
}

function* loadDividendsLTM() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/dividendsLTM`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_DIVIDENDS_LTM_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_DIVIDENDS_LTM_FAILURE
        })
    }
}

function* loadDividendsYearly() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/dividendsYearly`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_DIVIDENDS_YEARLY_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_DIVIDENDS_YEARLY_FAILURE
        })
    }
}

function* loadExpGroupedByCountry() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/exposureGroupedByCountry`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_EXP_GROUPED_BY_COUNTRY_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_EXP_GROUPED_BY_COUNTRY_FAILURE
        })
    }
}

function* loadExpGroupedBySector() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/exposureGroupedBySector`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_EXP_GROUPED_BY_SECTOR_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_EXP_GROUPED_BY_SECTOR_FAILURE
        })
    }
}

function* loadExpGroupedByIndustry() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/exposureGroupedByIndustry`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_EXP_GROUPED_BY_INDUSTRY_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_EXP_GROUPED_BY_INDUSTRY_FAILURE
        })
    }
}

function* loadXIRRBenchmark() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/xirrBenchmark`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_XIRR_BENCHMARK_SUCCESS,
            payload: ret.data.xirr
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_XIRR_BENCHMARK_FAILURE
        })
    }
}

function* loadPortfolioSecurities() {
    try {
        var token = window.localStorage.getItem("token");
        var ret = yield call(
            axios.get,
            `${config.LAMBDA_URL}/portfolioSecurities`,
            {headers: {token:token}}
        )
        yield put({
            type: LOAD_PORTFOLIO_SECURITIES_SUCCESS,
            payload: ret.data
        })
    } catch (err) {
        console.error(err);
        yield put({
            type: LOAD_PORTFOLIO_SECURITIES_FAILURE
        })
    }
}

export default function* () {
    yield takeLatest(LOAD_XIRR_REQUEST,loadXIRR);
    yield takeLatest(LOAD_TSPAN_REQUEST,loadTSpan);
    yield takeLatest(LOAD_PLTSPAN_REQUEST,loadPLTSpan);
    yield takeLatest(LOAD_PERF_METRICS_REQUEST,loadPerfMetrics);
    yield takeLatest(LOAD_PLBREAKDOWN_REQUEST,loadPLBreakdown);
    yield takeLatest(LOAD_PLBREAKDOWN_PCT_REQUEST,loadPLBreakdownPCT);
    yield takeLatest(LOAD_EXP_METRICS_REQUEST,loadExpMetrics);
    yield takeLatest(LOAD_MV_VS_INJECTION_REQUEST,loadMVvsInjection);
    yield takeLatest(LOAD_EXP_BREAKDOWN_REQUEST,loadExposureBreakdown);
    yield takeLatest(LOAD_RECENT_DIVIDENDS_REQUEST,loadRecentDividends);
    yield takeLatest(LOAD_DIVIDENDS_METRICS_REQUEST,loadDividendsMetrics);
    yield takeLatest(LOAD_DIVIDENDS_LTM_REQUEST,loadDividendsLTM);
    yield takeLatest(LOAD_DIVIDENDS_YEARLY_REQUEST,loadDividendsYearly);
    yield takeLatest(LOAD_EXP_GROUPED_BY_COUNTRY_REQUEST,loadExpGroupedByCountry);
    yield takeLatest(LOAD_EXP_GROUPED_BY_SECTOR_REQUEST,loadExpGroupedBySector);
    yield takeLatest(LOAD_EXP_GROUPED_BY_INDUSTRY_REQUEST,loadExpGroupedByIndustry);
    yield takeLatest(LOAD_XIRR_BENCHMARK_REQUEST,loadXIRRBenchmark);
    yield takeLatest(LOAD_PORTFOLIO_SECURITIES_REQUEST,loadPortfolioSecurities);
};
