import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
    GET_NOTIFICATIONS_REQUEST,
} from './types';
import axios from 'axios';


function* getNotifications() {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.LAMBDA_URL}/notifications`,
            {headers: {token: token}}
        )
        yield put({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: GET_NOTIFICATIONS_FAILURE });
    }
}



export default function* () {
    yield takeEvery(GET_NOTIFICATIONS_REQUEST, getNotifications);
};
