import {
    GET_NOTIFICATIONS_REQUEST,
    RESET_NOTIFICATIONS_STATE_REQUEST
} from './types';

export const getNotifications = () => ({
    type: GET_NOTIFICATIONS_REQUEST,
});

export const resetNotificationsState = () => ({
    type: RESET_NOTIFICATIONS_STATE_REQUEST
})