import {
    TXN_FORM_UPDATE_TXN_TYPE,
    SHOW_ADD_TXN_FORM,
    SHOW_EDIT_TXN_FORM,
    TXN_FORM_LOAD_ALL_TXN_REQUEST,
    TXN_FORM_DELETE_TXN_REQUEST,
    TXN_FORM_LOAD_TXN_REQUEST,
    TXN_FORM_ADD_TXN_REQUEST,
    TXN_FORM_EDIT_TXN_REQUEST,
    RESET_TXN_STATE_REQUEST,
    SEARCH_SECURITY_REQUEST,    
    SELECT_EXCHANGE_REQUEST,
    SELECT_SECURITY_REQUEST,
} from './types';

export const selectSecurity = (security) => ({
    type: SELECT_SECURITY_REQUEST,
    payload: security
});

export const selectExchange = (exchange) => ({
    type: SELECT_EXCHANGE_REQUEST,
    payload: exchange
});

export const searchSecurity = (security, exchange) => ({
    type: SEARCH_SECURITY_REQUEST,
    payload: {
        security: String(security).toUpperCase(),
        exchange: exchange
    }
});

export const updateTxnType = (txnType) => ({
    type: TXN_FORM_UPDATE_TXN_TYPE,
    payload: txnType
});

export const showAddTransaction = (show) => ({
    type: SHOW_ADD_TXN_FORM,
    payload: show
});

export const showEditTransaction = (show) => ({
    type: SHOW_EDIT_TXN_FORM,
    payload: show
});

export const loadAllTransactions = () => ({
    type: TXN_FORM_LOAD_ALL_TXN_REQUEST
});

export const deleteTransaction = (key) => ({
    type: TXN_FORM_DELETE_TXN_REQUEST,
    payload: key
});

export const loadTransaction = (key) => ({
    type: TXN_FORM_LOAD_TXN_REQUEST,
    payload: key
});

export const addTransaction = (txn) => ({
    type: TXN_FORM_ADD_TXN_REQUEST,
    payload: txn
});

export const editTransaction = (txn) => ({
    type: TXN_FORM_EDIT_TXN_REQUEST,
    payload: txn
});

export const resetTransactionsState = () => ({
    type: RESET_TXN_STATE_REQUEST
});
